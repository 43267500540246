import { ISignUpFormData } from "../interfaces/formData/SignupFormData.interface";
import { ISignupFormDataValidation } from "../interfaces/validations/signupFormValidation.interface";

export const signupFormValidation = ({email, password, password2}: ISignUpFormData): ISignupFormDataValidation => {
    let errors = {} as ISignupFormDataValidation
    if (email.length === 0) {
        errors = { ...errors, email: 'Required' };
      } else if (
        !String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        errors = { ...errors, email: 'Valid Email Required' };
      }
      if (password.length < 6) {
        errors = { ...errors, password: 'Atleast Length 6' };
      }
      if (password !== password2){
        errors = {...errors, password2: "Passwords Do not Match"}
      }
      return errors;
}