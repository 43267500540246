import { FC, Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import './App.css';
import { actions, urls } from './dispatch';
import { dispatch } from './dispatch/dispatch';
import ContextStore from './hooks/ContextStore';
import { IContextStore } from './interfaces/ContextStore.interface';
import { IErrorResponse } from './interfaces/responses/ErrorResponse.interface';
import { IProductGroupResponse } from './interfaces/responses/ProductGroupResponse.interface';
import { IProductResponse } from './interfaces/responses/ProductResponse.interface';
import { IStoreResponse } from './interfaces/responses/StoreResponse.interface';
import { IUserResponse } from './interfaces/responses/UserResponse.interface';
import { Spinkit } from './modals';
import RouteProvider from './routes/RouteProvider';

const App: FC = () => {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [contextStore, setContextStore] = useState<IContextStore>({
    token: '',
    user: {} as IUserResponse,
    store: {} as IStoreResponse,
    socket: { connected: false } as Socket,
    logs: [],
    products: [],
    storeUpdate: 0,
    productGroups: [],
    productGroupTrigger: (productGroups: Array<IProductGroupResponse>) => {},
    productTrigger: (products: Array<IProductResponse>) => {},
    overviewTrigger: () => {},
    date: {
      fromDate: new Date(Date.now()),
      toDate: new Date(Date.now()),
    },
    // showSpinner,
    setShowSpinner,
    onClickExport: () => {}
  });
  return (
    <ContextStore.Provider value={{ contextStore, setContextStore }}>
      <Fragment>
        {showSpinner && <Spinkit />}
        {/* <AuthContextProvider>
        
      </AuthContextProvider>
      <Toaster position="top-right" /> */}
        <RouteProvider />
      </Fragment>
    </ContextStore.Provider>
  );
};

export default App;
