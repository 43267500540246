import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext
} from 'react';
import ReactDOM from 'react-dom';
import { actions } from '../../dispatch';
import { dispatch } from '../../dispatch/dispatch';
import ContextStore from '../../hooks/ContextStore';
import { ICreateGroupFormData } from '../../interfaces/formData/CreateGroupFormData.interface';
import { IErrorResponse } from '../../interfaces/responses/ErrorResponse.interface';
import { IProductGroupResponse } from '../../interfaces/responses/ProductGroupResponse.interface';
import { IProductResponse } from '../../interfaces/responses/ProductResponse.interface';

interface ICreateGroupProps {
  setShowModal: Dispatch<SetStateAction<boolean>>
  products: Array<IProductResponse>,
}

export const CreateGroupModal: FC<ICreateGroupProps> = ({ setShowModal, products }) => {
  const {contextStore, setContextStore} = useContext(ContextStore)
  const ref = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<ICreateGroupFormData>({
    name: "",
    buyingRate: 0,
    storageFees: 0,
    transportFees: 0,
    packageFees: 0
  })
  const onChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }
  const [showForm, setShowForm] = useState<boolean>(true);
  const onClickCancel = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setShowModal(false)
  }
  const onClickAdd = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    contextStore.setShowSpinner(true)
    const productGroupResponse: IProductGroupResponse | IErrorResponse = await dispatch(actions.createProductGroup, {...formData, productIds: products.map(product=> product._id), storeId: contextStore.store._id}, contextStore.token)
    if("error" in productGroupResponse){
      setShowModal(false)
      contextStore.setShowSpinner(false)
      return console.log(productGroupResponse.error.message)
    }
    const productGroupProductsResponse: Array<IProductResponse> | IErrorResponse = await dispatch(actions.getProductGroupProducts, {fromDate: contextStore.date.fromDate, toDate: contextStore.date.toDate},contextStore.token, {productGroupId: productGroupResponse._id})
    if("error" in productGroupProductsResponse){
      return console.log(productGroupProductsResponse.error.message)
    }
    console.log(productGroupProductsResponse)
    const vProducts = contextStore.products
    productGroupProductsResponse.map(product => {
      const index = vProducts.findIndex(vProduct => vProduct._id === product._id)
      if(index !== -1){
        vProducts[index] = product
      }
    })
    
    let vProductGroups = contextStore.productGroups
    vProductGroups.push(productGroupResponse)
    contextStore.overviewTrigger(vProducts)
    contextStore.productTrigger(vProducts)
    contextStore.productGroupTrigger(vProductGroups, vProducts)
    setContextStore((past: any) => {
      return {
        ...past,
        productGroups: vProductGroups,
        products: vProducts
      }
    })
    contextStore.setShowSpinner(false)
    setShowModal(false)
  }
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showForm && ref.current && !ref.current.contains(e.target)) {
        setShowForm(false);
        setShowModal(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showForm]);

  return ReactDOM.createPortal(
    <div className='modal'>
      <form ref={ref} className='modal__container'>
        <div className='modal__header'>Create Group</div>
        <div className='modal__body'>
          <div className='modal__inputContainer'>
            <div className='modal__label' >Name</div>
            <input className='modal__input' name='name' type='text' value={formData.name} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label' >Buying Price</div>
            <input className='modal__input' name='buyingRate' type='number' value={formData.buyingRate} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label' >Transport Fees</div>
            <input className='modal__input' name='transportFees' type='number' value={formData.transportFees} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label' >Storage Fees</div>
            <input className='modal__input' name='storageFees' type='number' value={formData.storageFees} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label' >Package Fees</div>
            <input className='modal__input' name='packageFees' type='number' value={formData.packageFees} onChange={onChangeFormData}/>
          </div>

        </div>
        <div className='modal__footer'>
          <button className='modal__button-margin button bg-secondaryWhite' onClick={onClickCancel}>
            Cancel
          </button>
          <button className='modal__button button bg-blue' onClick={onClickAdd}>Add</button>
        </div>
      </form>
    </div>,
    document.getElementById('createGroup')!
  );
};
