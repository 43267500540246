import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import ReactDOM from 'react-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ContextStore from '../../hooks/ContextStore';
import { IProductGroupResponse } from '../../interfaces/responses/ProductGroupResponse.interface';
import { IProductResponse } from '../../interfaces/responses/ProductResponse.interface';
import { IDeleteResponse } from '../../interfaces/responses/DeleteResponse.interface';
import { IErrorResponse } from '../../interfaces/responses/ErrorResponse.interface';
import { dispatch } from '../../dispatch/dispatch';
import { actions } from '../../dispatch';

interface IAddAllToGroupProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  products: Array<IProductResponse>;
}

export const AddAllToGroupModal: FC<IAddAllToGroupProps> = ({
  setShowModal,
  products,
}) => {
  const ref = useRef<HTMLFormElement>(null);
  const { contextStore, setContextStore } = useContext(ContextStore);
  const [searchString, setSearchString] = useState<string>("")
  const [productGroups, setProductGroups] = useState<Array<IProductGroupResponse>>(contextStore.productGroups)
  const [showForm, setShowForm] = useState<boolean>(true);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IProductGroupResponse>(
    {} as IProductGroupResponse
  );

  const onClickCancel = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setShowModal(false)
  };
  const onClickSubmit = async (e: React.SyntheticEvent) => {
   
    e.preventDefault();
    if (selectedGroup._id) {
      contextStore.setShowSpinner(true)
      const addGroupsResponse: IDeleteResponse | IErrorResponse =
        await dispatch(
          actions.addProducts,
          { productIds: products.map((product) => product._id) },
          contextStore.token,
          { id: selectedGroup._id }
        );
      if ('error' in addGroupsResponse) {
        contextStore.setShowSpinner(false)
        return console.log(addGroupsResponse.error.message);
      }
      const productGroupProductsResponse:
        | Array<IProductResponse>
        | IErrorResponse = await dispatch(
        actions.getProductGroupProducts,
        {
          fromDate: contextStore.date.fromDate,
          toDate: contextStore.date.toDate,
        },
        contextStore.token,
        { productGroupId: selectedGroup._id }
      );
      if ('error' in productGroupProductsResponse) {
        contextStore.setShowSpinner(false)
        return console.log(productGroupProductsResponse.error.message);
      }
      const vProducts = contextStore.products;
      productGroupProductsResponse.map((product) => {
        const index = vProducts.findIndex(
          (vProduct) => vProduct._id === product._id
        );
        if (index !== -1) {
          vProducts[index] = product;
        }
      });
      contextStore.productGroupTrigger(contextStore.productGroups, vProducts);
      contextStore.overviewTrigger(vProducts);
      contextStore.productTrigger(vProducts);
      contextStore.setShowSpinner(false)
      setContextStore((past: any) => {
        return {
          ...past,
          products: vProducts,
        };
      });
    }
    setShowModal(false);
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showForm && ref.current && !ref.current.contains(e.target)) {
        setShowForm(false);
        setShowModal(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showForm]);
  const onChangeSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductGroups(contextStore.productGroups.filter(productGroup => productGroup.name.toLowerCase().includes(e.target.value.toLowerCase())))
    setSearchString(e.target.value)
  }
  return ReactDOM.createPortal(
    <div className='modal'>
      <form ref={ref} className='modal__container'>
        <div className='modal__header'>Add to Group</div>
        <div className='modal__body'>
          <div style={{ position: 'relative' }}>
            <div className='modal__inputContainer'>
              <div
                className='modal__dropDowninput'
                onClick={() => {
                  setOpenDropdown(!openDropdown);
                }}>
                <div>
                  {!selectedGroup.name ? 'Select Group' : selectedGroup.name}
                </div>
                {!openDropdown ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </div>
            </div>
            {openDropdown && (
              <div className='modal__dropDownOptions'>
                <div className='modal__dropDownScroll'>
                  {productGroups.map((productGroup) => (
                    <div
                      className='modal__dropDownOption'
                      onClick={() => {
                        setSelectedGroup(productGroup);
                        setOpenDropdown(!openDropdown);
                      }}>
                      {productGroup.name}
                    </div>
                  ))}
                </div>
                <input
                  className='modal__dropDownOption'
                  name='name'
                  type='text'
                  placeholder='Search group'
                  value={searchString}
                  onChange={onChangeSearchString}
                />
              </div>
            )}
          </div>
        </div>
        <div className='modal__footer'>
          <button
            className='modal__button-margin button bg-secondaryWhite'
            onClick={onClickCancel}>
            Cancel
          </button>
          <button
            className='modal__button button bg-blue'
            onClick={onClickSubmit}
            disabled={!selectedGroup.name}>
            Add
          </button>
        </div>
      </form>
    </div>,
    document.getElementById('addToGroup')!
  );
};
