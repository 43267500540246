import React, { FC, Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { actions } from "../../../dispatch";
import { dispatch } from "../../../dispatch/dispatch";
import ContextStore from "../../../hooks/ContextStore";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { FromToDateSelect } from "../../../interfaces/formData/FromToDateSelect.interface";
import { IOverviewTableValues } from "../../../interfaces/formData/OverViewTableValues.interface";
import { IErrorResponse } from "../../../interfaces/responses/ErrorResponse.interface";
import { IProductGroupResponse } from "../../../interfaces/responses/ProductGroupResponse.interface";
import { IProductResponse } from "../../../interfaces/responses/ProductResponse.interface";
import { IStoreResponse } from "../../../interfaces/responses/StoreResponse.interface";
import { CreateGroupModal } from "../../../modals";
import "./Analytics.component.css";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { fromDateToString, fromStringToDate } from "./utils";
import { IAnalyticsResponse } from "../../../interfaces/responses/AnalyticsResponse.interface";

export const Analytics: FC = () => {
    const { contextStore, setContextStore } = useContext(ContextStore);
    const { id } = useParams();
    const fixedDate: FromToDateSelect = {
        fromDate: new Date(2022, 5, 3),
        toDate: new Date(Date.now()),
    };
    const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        let vDate = contextStore.date;
        vDate = { ...vDate, [e.target.name]: fromStringToDate(e.target.value) };
        setContextStore((past: any) => {
            return { ...past, date: vDate };
        });
    };
    const [graphData, setGraphData] = useState<Array<IAnalyticsResponse>>([]);
    const [dateUpdate, setDateUpdate] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [tableValues, setTableValues] = useState<IOverviewTableValues>({
        deliveredOrders: 0,
        canceledOrders: 0,
        returnedOrders: 0,
        deliveredPercentage: 0,
        totalPayout: 0,
        totalBuyingRate: 0,
        totalTransportFees: 0,
        totalStorageFees: 0,
        totalPackageFees: 0,
        totalProfit: 0,
        roi: 0,
        profitPerProduct: 0,
        failedOrders: 0,
        totalOrders: 0,
        totalLoss: 0,
        progressDeliveredOrders: 0,
        progressProfit: 0,
        pendingOrders: 0,
        shippedOrders: 0,
        readyToShipOrders: 0,
        progressTotalOrders: 0,
    });
    const tableData = [
        {
            name: "Number of orders delivered",
            value: tableValues.deliveredOrders,
        },
        {
            name: `Progress (Delivered Orders)%`,
            value: tableValues.progressDeliveredOrders,
        },
        {
            name: "Number of orders canceled",
            value: tableValues.canceledOrders,
        },
        {
            name: "Number of orders returned",
            value: tableValues.returnedOrders,
        },
        { name: "Number of order failed", value: tableValues.failedOrders },
        {
            name: "Percentage or orders Delivered",
            value: tableValues.deliveredPercentage.toFixed(0),
        },
        { name: "Number of orders shipped", value: tableValues.shippedOrders },
        { name: "Number of orders pending", value: tableValues.pendingOrders },
        {
            name: "Number of order ready to ship",
            value: tableValues.readyToShipOrders,
        },
        { name: "Total Orders", value: tableValues.totalOrders },
        {
            name: `Progress (Total Orders)%`,
            value: tableValues.progressTotalOrders,
        },
        { name: "Total Payout", value: tableValues.totalPayout.toFixed(2) },
        {
            name: "Total Product Buying Cost",
            value: tableValues.totalBuyingRate.toFixed(2),
        },
        {
            name: "Total Product Transport Fees",
            value: tableValues.totalTransportFees.toFixed(2),
        },
        {
            name: "Total Product Storage Fees",
            value: tableValues.totalStorageFees.toFixed(2),
        },
        {
            name: "Total Product Package Fees",
            value: tableValues.totalPackageFees.toFixed(2),
        },
        { name: "Total Profit", value: tableValues.totalProfit.toFixed(2) },
        { name: `Progress (Total Profit)%`, value: tableValues.progressProfit },
        { name: "roi", value: tableValues.roi.toFixed(0) },
        { name: "Profit Per Product", value: tableValues.profitPerProduct },
        { name: "Total Loss", value: tableValues.totalLoss },
    ];

    useEffect(() => {
        (async () => {
            if (id !== "0") {
                contextStore.setShowSpinner(true);
                const storeResponse: IStoreResponse | IErrorResponse =
                    await dispatch(actions.getStore, {}, contextStore.token, {
                        id,
                    });
                if ("error" in storeResponse) {
                    contextStore.setShowSpinner(false);
                    return console.log(storeResponse.error.message);
                }
                const productsResponse:
                    | Array<IProductResponse>
                    | IErrorResponse = await dispatch(
                    actions.getStoreProducts,
                    {
                        fromDate: contextStore.date.fromDate.toISOString(),
                        toDate: contextStore.date.toDate.toISOString(),
                    },
                    contextStore.token,
                    { storeId: id }
                );
                if ("error" in productsResponse) {
                    contextStore.setShowSpinner(false);
                    return console.log(productsResponse.error.message);
                }
                const productGroupsResponse:
                    | Array<IProductGroupResponse>
                    | IErrorResponse = await dispatch(
                    actions.getStoreProductGroups,
                    {},
                    contextStore.token,
                    { storeId: id }
                );
                if ("error" in productGroupsResponse) {
                    contextStore.setShowSpinner(false);
                    return console.log(productGroupsResponse.error.message);
                }
                console.log({
                    fromDate: contextStore.date.fromDate.toISOString(),
                    toDate: contextStore.date.toDate.toISOString(),
                    storeId: id,
                });
                const analyticsResponse:
                    | Array<IAnalyticsResponse>
                    | IErrorResponse = await dispatch(
                    actions.getAnalyticsAll,
                    {
                        fromDate: contextStore.date.fromDate.toISOString(),
                        toDate: contextStore.date.toDate.toISOString(),
                        storeId: id,
                    },
                    contextStore.token
                );
                if ("error" in analyticsResponse) {
                    contextStore.setShowSpinner(false);
                    return console.log(analyticsResponse.error.message);
                }
                setGraphData(
                    analyticsResponse.map((data) => {
                        data.totalProfit = parseFloat(
                            data.totalProfit.toFixed(2)
                        );
                        return data;
                    })
                );
                setContextStore((past: any) => {
                    return {
                        ...past,
                        products: productsResponse,
                        store: storeResponse,
                        productGroups: productGroupsResponse,
                    };
                });
                contextStore.setShowSpinner(false);
            } else {
                setContextStore((past: any) => {
                    return {
                        ...past,
                        products: [],
                        store: {} as IStoreResponse,
                        productGroups: [],
                    };
                });
            }
        })();
    }, [id, contextStore.storeUpdate, dateUpdate]);
    const updateProducts = (products: Array<IProductResponse>) => {
        let vValues = {
            deliveredOrders: 0,
            canceledOrders: 0,
            returnedOrders: 0,
            deliveredPercentage: 0,
            totalPayout: 0,
            totalBuyingRate: 0,
            totalTransportFees: 0,
            totalStorageFees: 0,
            totalPackageFees: 0,
            totalProfit: 0,
            roi: 0,
            failedOrders: 0,
            totalOrders: 0,
            profitPerProduct: 0,
            totalLoss: 0,
            progressDeliveredOrders: 0,
            progressProfit: 0,
            pendingOrders: 0,
            shippedOrders: 0,
            readyToShipOrders: 0,
            progressTotalOrders: 0,
        } as IOverviewTableValues;
        let progressDeliveredOrdersBefore = 0;
        let progressProfitBefore = 0;
        let progressDeliveredOrdersAfter = 0;
        let progressProfitAfter = 0;
        let progressTotalOrdersBefore = 0;
        let progressTotalOrdersAfter = 0;
        products.map((product) => {
            vValues.deliveredOrders += product.deliveredOrders;
            vValues.canceledOrders += product.canceledOrders;
            vValues.returnedOrders += product.returnedOrders;
            vValues.pendingOrders += product.pendingOrders;
            vValues.shippedOrders += product.shippedOrders;
            vValues.readyToShipOrders += product.readyToShipOrders;
            vValues.deliveredPercentage += product.deliveredPercentage;
            vValues.totalPayout += product.totalPayout;
            vValues.totalBuyingRate += product.totalBuyingRate;
            vValues.totalTransportFees += product.totalTransportFees;
            vValues.totalStorageFees += product.totalStorageFees;
            vValues.totalPackageFees += product.totalPackageFees;
            vValues.totalProfit += product.totalProfit;
            vValues.roi += product.roi;
            vValues.failedOrders += product.failedOrders;
            vValues.totalOrders +=
                product.deliveredOrders +
                product.returnedOrders +
                product.canceledOrders +
                product.failedOrders +
                product.pendingOrders +
                product.shippedOrders +
                product.readyToShipOrders;
            vValues.profitPerProduct += product.profitPerProduct;
            vValues.totalLoss += product.loss;
            progressDeliveredOrdersBefore +=
                product.progressDeliveredOrdersBefore;
            progressDeliveredOrdersAfter +=
                product.progressDeliveredOrdersAfter;
            progressProfitBefore += product.progressProfitBefore;
            progressProfitAfter += product.progressProfitAfter;
            progressTotalOrdersAfter += product.progressTotalOrdersAfter;
            progressTotalOrdersBefore += product.progressTotalOrdersBefore;
            progressProfitAfter += product.progressProfitAfter;
            progressProfitBefore += product.progressProfitBefore;
        });
        vValues.deliveredPercentage = parseFloat(
            (
                (vValues.deliveredOrders /
                    (vValues.deliveredOrders + vValues.returnedOrders)) *
                100
            ).toFixed(2)
        );
        vValues.roi = parseFloat(
            ((vValues.totalProfit / vValues.totalBuyingRate) * 100).toFixed(2)
        );
        vValues.profitPerProduct = parseFloat(
            (vValues.totalProfit / vValues.deliveredOrders).toFixed(2)
        );
        if (progressDeliveredOrdersBefore != 0) {
            vValues.progressDeliveredOrders = parseFloat(
                (
                    ((progressDeliveredOrdersAfter -
                        progressDeliveredOrdersBefore) /
                        progressDeliveredOrdersBefore) *
                    100
                ).toFixed(2)
            );
        }
        if (progressProfitBefore != 0) {
            vValues.progressProfit = parseFloat(
                (
                    ((progressProfitAfter - progressProfitBefore) /
                        progressProfitBefore) *
                    100
                ).toFixed(2)
            );
        }
        if (progressTotalOrdersBefore != 0) {
            vValues.progressTotalOrders = parseFloat(
                (
                    ((progressTotalOrdersAfter - progressTotalOrdersBefore) /
                        progressTotalOrdersBefore) *
                    100
                ).toFixed(2)
            );
        }
        setTableValues(vValues);
    };
    useEffect(() => {
        setContextStore((past: any) => {
            return {
                ...past,
                overviewTrigger: updateProducts,
            };
        });
    }, []);
    useEffect(() => {
        updateProducts(contextStore.products);
        contextStore.productGroupTrigger(
            contextStore.productGroups,
            contextStore.products
        );
        contextStore.productTrigger(contextStore.products);
    }, [
        contextStore.productGroupTrigger,
        contextStore.products,
        contextStore.productGroups,
        contextStore.productTrigger,
    ]);
    const [legend, setLegend] = useState<string[]>([
        contextStore.user.type === "admin" ? "totalProfit" : "deliveredOrders",
    ]);
    const selectLegend = (value: string) => {
        setLegend([...legend, value]);
    };

    const deselectLegend = (value: string) => {
        const filteredOptions = legend.filter((l) => l !== value);
        setLegend([...filteredOptions]);
    };

    const handleLegend = (value: string) => {
        legend.some((l) => l === value)
            ? deselectLegend(value)
            : selectLegend(value);
    };
    return (
        <Fragment>
            <div className="tableAnalysis__nameContainer">
                <div className="tableAnalysis__name">Analytics</div>
            </div>
            <div className="analytics__container">
                <div className="analytics__numberDataContainer">
                    {contextStore.user.type === "admin" && (
                        <table className="analytics__table">
                            <thead>
                                <tr>
                                    <th className="text-start ">Categories</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>

                            <tbody>
                                {tableData.map((data, index) => (
                                    <tr
                                        className="analytics__tableRow"
                                        key={index}
                                    >
                                        <td>{data.name}</td>
                                        <td className="text-end">
                                            {data.value}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="analytics__chartContainer">
                    <div>
                        <div>
                            <label className="modal__label">Start Date</label>
                            <input
                                className="modal__input"
                                type="date"
                                value={fromDateToString(
                                    contextStore.date.fromDate
                                )}
                                min={fromDateToString(fixedDate.fromDate)}
                                max={fromDateToString(contextStore.date.toDate)}
                                name="fromDate"
                                onChange={onChangeDate}
                            />
                        </div>
                        <div style={{ margin: "2rem 0rem" }}>
                            <label className="modal__label">End Date</label>
                            <input
                                className="modal__input"
                                type="date"
                                value={fromDateToString(
                                    contextStore.date.toDate
                                )}
                                min={fromDateToString(
                                    contextStore.date.fromDate
                                )}
                                max={fromDateToString(fixedDate.toDate)}
                                name="toDate"
                                onChange={onChangeDate}
                            />
                        </div>
                        <button
                            className="modal__button button bg-blue"
                            onClick={() => {
                                setDateUpdate(dateUpdate + 1);
                            }}
                        >
                            Set Date
                        </button>
                    </div>
                </div>
            </div>
            <div className="tableAnalysis__expand_analytics">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1rem",
                        margin: "1rem 0rem",
                    }}
                >
                    {contextStore.user.type === "admin" && (
                        <div
                            className={`analytics__legendOutline ${
                                legend.some((l) => l === "totalProfit") &&
                                "bg-blue text-white"
                            }`}
                            onClick={() => {
                                handleLegend("totalProfit");
                            }}
                        >
                            Total Profit
                        </div>
                    )}
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "deliveredOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("deliveredOrders");
                        }}
                    >
                        Delivered Orders
                    </div>
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "returnedOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("returnedOrders");
                        }}
                    >
                        Returned Orders
                    </div>
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "failedOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("failedOrders");
                        }}
                    >
                        Failed Orders
                    </div>
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "canceledOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("canceledOrders");
                        }}
                    >
                        Canceled Orders
                    </div>
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "totalOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("totalOrders");
                        }}
                    >
                        Total Orders
                    </div>
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "shippedOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("shippedOrders");
                        }}
                    >
                        Shipped Orders
                    </div>
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "readyToShipOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("readyToShipOrders");
                        }}
                    >
                        RTS Orders
                    </div>
                    <div
                        className={`analytics__legendOutline ${
                            legend.some((l) => l === "pendingOrders") &&
                            "bg-blue text-white"
                        }`}
                        onClick={() => {
                            handleLegend("pendingOrders");
                        }}
                    >
                        Pending Orders
                    </div>
                </div>
                <ResponsiveContainer width="99%" aspect={7}>
                    <AreaChart
                        data={graphData}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        {/* <defs>
                <linearGradient
                  id='colortotalProfit'
                  x1='0'
                  y1='0'
                  x2='0'
                  y2='1'>
                  <stop offset='5%' stopColor='#00aeef' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#00aeef' stopOpacity={0} />
                </linearGradient>
              </defs> */}
                        <XAxis dataKey="date" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        {/* <Legend verticalAlign='top' height={40} /> */}

                        {contextStore.user.type === "admin" &&
                            legend.some((l) => l === "totalProfit") && (
                                <Area
                                    type="monotone"
                                    dataKey="totalProfit"
                                    // name='New Patient'
                                    stroke="#121212"
                                    fillOpacity={0.5}
                                    fill="#00aeef"
                                />
                            )}
                        {legend.some((l) => l === "deliveredOrders") && (
                            <Area
                                type="monotone"
                                dataKey="deliveredOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#52b041"
                            />
                        )}
                        {legend.some((l) => l === "returnedOrders") && (
                            <Area
                                type="monotone"
                                dataKey="returnedOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#b04141"
                            />
                        )}
                        {legend.some((l) => l === "failedOrders") && (
                            <Area
                                type="monotone"
                                dataKey="failedOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#b0aa41"
                            />
                        )}
                        {legend.some((l) => l === "canceledOrders") && (
                            <Area
                                type="monotone"
                                dataKey="canceledOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#b07741"
                            />
                        )}
                        {legend.some((l) => l === "totalOrders") && (
                            <Area
                                type="monotone"
                                dataKey="totalOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#b041b0"
                            />
                        )}
                        {legend.some((l) => l === "pendingOrders") && (
                            <Area
                                type="monotone"
                                dataKey="pendingOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#223a78"
                            />
                        )}
                        {legend.some((l) => l === "shippedOrders") && (
                            <Area
                                type="monotone"
                                dataKey="shippedOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#6f2278"
                            />
                        )}
                        {legend.some((l) => l === "readyToShipOrders") && (
                            <Area
                                type="monotone"
                                dataKey="readyToShipOrders"
                                // name='Old Patient'
                                stroke="#121212"
                                fillOpacity={0.5}
                                fill="#78223f"
                            />
                        )}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </Fragment>
    );
};
