export const actions = {
    signup: "SIGNUP",
    login: "LOGIN",
    getUser: "GETUSER",
    getStore: "GETSTORE",
    getLogs:  "GETLOGS",
    createStore: "CREATESTORE",
    getStores: "GETSTORES",
    getStoreProducts: "GETSTOREPRODUCTS",
    createProductGroup: "CREATEPRODUCTGROUP",
    getStoreProductGroups: "GETSTOREPRODUCTGROUPS",
    editProduct: "EDITPRODUCT",
    deleteProductGroup: "DELETEPRODUCTGROUP",
    reInitiateStore: "REINITIATESTORE",
    deleteStore: "DELETESTORE",
    editProductGroup: "EDITPRODUCTGROUP",
    getProductGroupProducts: "GETPRODUCTGROUPPRODUCTS",
    addProduct: "ADDPRODUCT",
    removeProduct: "REMOVEPRODUCT",
    getProduct: "GETPRODUCT",
    addProducts: "ADDPRODUCTS",
    getAllProductGroupProducts: "GETAllPRODUCTGROUPPRODUCTS",
    clearLog: "CLEARLOG",
    getAnalytics: "GETANALYTICS",
    getAnalyticsProduct: "GETANALYTICSPRODUCT",
    getAnalyticsAll: "GETANALYTICSALL",
    getShipmentProviders: "GETSHIPMENTPROVIDERS",
    startPendingBot: "STARTPENDINGBOT",
    stopPendingBot: "STOPPENDINGBOT",
    addReadyToShipBotEmail: "ADDREADYTOSHIPBOTEMAIL",
    deleteReadyToShipBotEmail: "DELETEREADYTOSHIPBOTEMAIL",
    addXlsxBotEmail: "ADDXLSXBOTEMAIL",
    deleteXlsxBotEmail: "DELETEXLSXBOTEMAIL",
    startReadyToShipBot: "startReadyToShipBot",
    stopReadyToShipBot: "stopReadyToShipBot",
    
}