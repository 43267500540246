import { FC, Fragment } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

interface IPagination {
  pageNo: number;
  totalPages: number;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
}

export const Pagination: FC<IPagination> = ({
  pageNo,
  totalPages,
  setPageNo,
}) => {
  const moveToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setPageNo(page);
    }
  };
  return (
    <Fragment>
      <div className='table__pagination'>
        <div
          className='table__paginationActionLeft'
          onClick={() => {
            moveToPage(1);
          }}>
          <KeyboardDoubleArrowLeftIcon />
        </div>
        <div
          className='table__paginationContainer'
          onClick={() => {
            moveToPage(pageNo - 1);
          }}>
          <KeyboardArrowLeftIcon />
        </div>
        {pageNo === totalPages && pageNo >= 2 && (
          <div
            className='table__paginationContainer'
            onClick={() => {
              moveToPage(pageNo - 2);
            }}>
            {pageNo - 2}
          </div>
        )}
        {pageNo !== 1 && (
          <div
            className='table__paginationContainer'
            onClick={() => {
              moveToPage(pageNo - 1);
            }}>
            {pageNo - 1}
          </div>
        )}
        <div className='table__paginationContainerActive'>{pageNo}</div>
        {pageNo !== totalPages && (
          <div
            className='table__paginationContainer'
            onClick={() => {
              moveToPage(pageNo + 1);
            }}>
            {pageNo + 1}
          </div>
        )}
        {pageNo + 1 <= totalPages && pageNo === 1 && (
          <div
            className='table__paginationContainer'
            onClick={() => {
              moveToPage(pageNo + 2);
            }}>
            {pageNo + 2}
          </div>
        )}
        <div
          className='table__paginationContainer'
          onClick={() => {
            moveToPage(pageNo + 1);
          }}>
          <KeyboardArrowRightIcon />
        </div>
        <div
          className='table__paginationActionRight'
          onClick={() => {
            moveToPage(totalPages);
          }}>
          <KeyboardDoubleArrowRightIcon />
        </div>
      </div>
    </Fragment>
  );
};
