import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import ReactDOM from 'react-dom';

import ClearIcon from '@mui/icons-material/Clear';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import './EditProduct.modal.css';
import ContextStore from '../../hooks/ContextStore';
import { IProductGroupTableData } from '../../interfaces/formData/ProductGroupTableData.interace';
import { IEditProductGroupFormData } from '../../interfaces/formData/EditProductGroupFormData.interface';
import { dispatch } from '../../dispatch/dispatch';
import { actions } from '../../dispatch';
import { IProductGroupResponse } from '../../interfaces/responses/ProductGroupResponse.interface';
import { IErrorResponse } from '../../interfaces/responses/ErrorResponse.interface';
import { IProductResponse } from '../../interfaces/responses/ProductResponse.interface';

interface IEditProductModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  productGroup: IProductGroupTableData;
}

export const EditProductModal: FC<IEditProductModalProps> = ({
  setShowModal,
  productGroup,
}) => {
  const [formData, setFormData] = useState<IEditProductGroupFormData>({
    _id: productGroup._id,
    name: productGroup.name,
    skus: productGroup.skus,
    buyingRate: productGroup.buyingRate,
    packageFees: productGroup.packageFees,
    transportFees: productGroup.transportFees,
    storageFees: productGroup.storageFees,
    stockAvailable: productGroup.stockAvailable
  });
  const { contextStore, setContextStore } = useContext(ContextStore);
  const [expandDropdown, setExpandDropdown] = useState<boolean>(false);
  // const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    const products = contextStore.products.filter(
      (product) =>
        productGroup.skus.findIndex((sku) => sku === product.Sku) === -1
    );
    const skus = products.map((product) => product.Sku);
    setOptions(skus);
  }, []);
  const selectSku = (sku: string) => {
    setFormData({ ...formData, skus: [...formData.skus, sku] });
    // setSelectedOptions([...selectedOptions, sku]);
    const filteredOptions = options.filter((option) => option !== sku);
    setOptions([...filteredOptions]);
  };
  const deselectSku = (sku: string) => {
    setOptions([...options, sku]);
    const filteredOptions = formData.skus.filter(
      (option: any) => option !== sku
    );
    // setSelectedOptions([...filteredOptions]);
    setFormData({ ...formData, skus: [...filteredOptions] });
  };
  const onClickSubmit = async(e: React.SyntheticEvent) => {
    contextStore.setShowSpinner(true)
    e.preventDefault()
    const productGroupEditResponse: IProductGroupResponse | IErrorResponse = await dispatch(actions.editProductGroup, {...formData}, contextStore.token, {id: formData._id})
    if("error" in productGroupEditResponse){
      contextStore.setShowSpinner(false)
      return console.log(productGroupEditResponse.error.message)
    }
    const vProductGroups = contextStore.productGroups
    const index = vProductGroups.findIndex(productGroup => productGroup._id === productGroupEditResponse._id)
    vProductGroups[index] = productGroupEditResponse
    const productGroupProductsResponse: Array<IProductResponse> | IErrorResponse = await dispatch(actions.getProductGroupProducts, {fromDate: contextStore.date.fromDate, toDate: contextStore.date.toDate},contextStore.token, {productGroupId: formData._id})
    if("error" in productGroupProductsResponse){
      contextStore.setShowSpinner(false)
      return console.log(productGroupProductsResponse.error.message)
    }
    const vProducts = contextStore.products
    productGroupProductsResponse.map(product => {
      const index = vProducts.findIndex(vProduct => vProduct._id === product._id)
      if(index !== -1){
        vProducts[index] = product
      }
    })
    contextStore.overviewTrigger(vProducts)
    contextStore.productTrigger(vProducts)
    contextStore.productGroupTrigger(vProductGroups, vProducts)
    contextStore.setShowSpinner(false)
    setContextStore((past: any) => {
      return {
        ...past,
        products: vProducts,
        productGroups: vProductGroups
      }
    })
    setShowModal(false)
  }
  const onChangeFormData = async(e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }
  const onClickCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowModal(false);
  };
  const ref = useRef<HTMLFormElement>(null);

  const [showForm, setShowForm] = useState<boolean>(true);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showForm && ref.current && !ref.current.contains(e.target)) {
        setShowForm(false);
        setShowModal(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showForm]);

  return ReactDOM.createPortal(
    <div className='modal'>
      <form ref={ref} className='modal__container'>
        <div className='modal__header'>Edit Product</div>
        <div className='modal__body'>
        <div className='modal__inputContainer'>
            <div className='modal__label'>Name</div>
            <input className='modal__input' type='text' name='name' value={formData.name} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label'>Buying Price</div>
            <input className='modal__input' type='number' name='buyingRate' value={formData.buyingRate} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label'>Transport Fees</div>
            <input className='modal__input' type='number' name='transportFees' value={formData.transportFees} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label'>Storage Fees</div>
            <input className='modal__input' type='number' name='storageFees' value={formData.storageFees} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label'>Package Fees</div>
            <input className='modal__input' type='number' name='packageFees' value={formData.packageFees} onChange={onChangeFormData}/>
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label'>Stock Available</div>
            <input className='modal__input' type='number' name='stockAvailable' value={formData.stockAvailable} onChange={onChangeFormData}/>
          </div>
          {/* <div className='modal__inputContainer'>
            <label className='modal__label'>SKU</label>
            <div className='editProduct__skuContainer'>
              {formData.sku.map((option: string, index: number) => (
                <span className='selectedOption' key={index}>
                  <span style={{ flex: '1' }}>{option}</span>
                  <ClearIcon
                    onClick={() => {
                      deselectSku(option);
                    }}
                  />
                </span>
              ))}
            </div>
            <div className='addProduct__dropdown'>
              <div className='addProduct__dropdownContainer'>
                <div
                  className={`toggleDropdown`}
                  onClick={() => setExpandDropdown(!expandDropdown)}>
                  <div>Add more SKU</div>
                  {expandDropdown ? (
                    <ArrowLeftIcon className='large__svg' />
                  ) : (
                    <ArrowRightIcon className='large__svg' />
                  )}
                </div>
              </div>

              {expandDropdown && (
                <div className='addProduct__options'>
                  <div className='addProduct__optionsScroll'>
                    {options.map((option: string, index: number) => (
                      <div
                        className='addProduct__option'
                        key={index}
                        onClick={() => {
                          selectSku(option);
                        }}>
                        {option}
                      </div>
                    ))}
                  </div>
                  <input
                    className='addProduct__option heightl'
                    placeholder='Search'
                  />
                </div>
              )}
            </div>
          </div> */}
        </div>
        <div className='modal__footer'>
          <button
            className='modal__button-margin button bg-secondaryWhite'
            onClick={onClickCancel}>
            Cancel
          </button>
          <button
            className='modal__button button bg-blue'
            onClick={onClickSubmit}>
            Edit
          </button>
        </div>
      </form>
    </div>,
    document.getElementById('editProduct')!
  );
};
