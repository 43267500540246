import { FC, useState, useContext } from "react";
import { actions, urls } from "../../../dispatch";
import { dispatch } from "../../../dispatch/dispatch";
import { IErrorResponse } from "../../../interfaces/responses/ErrorResponse.interface";
import { ISignUpFormData } from "../../../interfaces/formData/SignupFormData.interface";
import { ISignupFormDataValidation } from "../../../interfaces/validations/signupFormValidation.interface";
import { signupFormValidation } from "../../../validations/signupFormValidation";
import { io } from "socket.io-client";
import "./Signup.component.css";
import ContextStore from "../../../hooks/ContextStore";
import { IUserResponse } from "../../../interfaces/responses/UserResponse.interface";
import { useNavigate } from "react-router-dom";

export const Signup: FC = () => {
    const navigate = useNavigate();
    const { contextStore, setContextStore } = useContext(ContextStore);
    const [formData, setFormData] = useState<ISignUpFormData>({
        email: "",
        password: "",
        password2: "",
    });
    const [errors, setErrors] = useState<
        ISignupFormDataValidation | undefined
    >();
    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        const vErrors = signupFormValidation(formData);
        setErrors(vErrors);
        console.log(vErrors);
        if (!vErrors.password && !vErrors.password2 && !vErrors.email) {
            contextStore.setShowSpinner(true)
            const tokenResponse: string | IErrorResponse = await dispatch(
                actions.signup,
                { email: formData.email, password: formData.password }
            );
            if (typeof tokenResponse === "string") {
                const userResponse: IUserResponse | IErrorResponse =
                    await dispatch(actions.getUser, {}, tokenResponse);
                if ("error" in userResponse) {
                    contextStore.setShowSpinner(false)
                    return console.log(userResponse.error.message);
                }
                localStorage.setItem("token", tokenResponse);
                const socket = io(urls.baseUrlSocketApi, {
                    reconnectionDelayMax: 10000,
                    auth: {
                        token: tokenResponse,
                    },
                });
                setContextStore((past: any) => {
                    return {
                        ...past,
                        user: userResponse,
                    token: tokenResponse,
                    socket,
                    }
                })
                contextStore.setShowSpinner(false)
                navigate("/");
            } else {
                contextStore.setShowSpinner(false)
                alert(tokenResponse.error.message)
                console.log(tokenResponse.error.message);
            }
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    return (
        <div className="login">
            <div className="login__container">
                <div className="login__logo">
                    <span className="accent">Order</span>
                    <span className="secondary">Bot</span>
                </div>
                <form className="login__form" onSubmit={handleSubmit}>
                    <div>
                        <label className="login__label">Email</label>
                        <input
                            value={formData.email}
                            type="text"
                            name="email"
                            className={
                                errors?.email
                                    ? "login__input error__input"
                                    : "login__input"
                            }
                            placeholder="admin@gmail.com"
                            onChange={handleChange}
                        />
                    </div>
                    {errors?.email ? (
                        <div className="danger__text">{errors.email}</div>
                    ) : (
                        <br />
                    )}
                    <br />
                    <div>
                        <label className="login__label">Password</label>
                        <input
                            value={formData.password}
                            type="password"
                            name="password"
                            className={
                                errors?.password
                                    ? "login__input error__input"
                                    : "login__input"
                            }
                            placeholder="Type your password"
                            onChange={handleChange}
                        />
                    </div>
                    {errors?.password ? (
                        <div className="danger__text">{errors.password}</div>
                    ) : (
                        <br />
                    )}
                    <br />
                    <div>
                        <label className="login__label">Confirm Password</label>
                        <input
                            value={formData.password2}
                            type="password"
                            name="password2"
                            className={
                                errors?.password2
                                    ? "login__input error__input"
                                    : "login__input"
                            }
                            placeholder="Re-Type your password"
                            onChange={handleChange}
                        />
                    </div>
                    {errors?.password2 ? (
                        <div className="danger__text">{errors.password2}</div>
                    ) : (
                        <br />
                    )}
                    <br />
                    <button type="submit" className="login__button">
                        Signup
                    </button>
                </form>
            </div>
        </div>
    );
};
