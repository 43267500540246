import { FC, Fragment, useState, useContext, useEffect, useRef } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import './Header.component.css';
import { AddStoreModal } from '../../../modals';
import ContextStore from '../../../hooks/ContextStore';
import { IUserResponse } from '../../../interfaces/responses/UserResponse.interface';
import { useNavigate, useParams } from 'react-router-dom';
import { IStoreResponse } from '../../../interfaces/responses/StoreResponse.interface';
import { IErrorResponse } from '../../../interfaces/responses/ErrorResponse.interface';
import { dispatch } from '../../../dispatch/dispatch';
import { actions } from '../../../dispatch';
import { ILogResponse } from '../../../interfaces/responses/LogResponse.interface';
import { IDeleteResponse } from '../../../interfaces/responses/DeleteResponse.interface';

export const Header: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { contextStore, setContextStore } = useContext(ContextStore);
  const [stores, setStores] = useState<Array<IStoreResponse>>([]);
  const [expandDropdown, setExpandDropdown] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [logsUpdate, setLogsUpdate] = useState<number>(0);
  const logsRef = useRef<Array<ILogResponse>>([]);
  const setLogs = (vLogs: Array<ILogResponse>) => {
    logsRef.current = vLogs;
    setLogsUpdate((past) => past + 1);
  };
  const onClickProductGroup = () => {
    navigate('/productgroup/update');
  };
  const onClickProductGroupOrderDate= () => {
    navigate('/productgroup/order');
  };
  const onSelectStore = (storeId: string) => {
    if (id !== '0') {
      contextStore.socket.emit('leaveStoreLogsRoom', [id]);
    }
    setExpandDropdown(false);
    navigate(`/dashboard/${storeId}`);
  };
  const onClickLogout = () => {
    localStorage.removeItem('token');
    if (contextStore.socket.connected) {
      contextStore.socket.disconnect();
    }
    setContextStore((past: any) => {
      return { ...past, token: '', user: {} as IUserResponse };
    });
    window.location.reload();
  };
  const onClickReinitiate = async () => {
    contextStore.setShowSpinner(true);
    const storeResponse: IStoreResponse | IErrorResponse = await dispatch(
      actions.reInitiateStore,
      {},
      contextStore.token,
      { id }
    );
    if ('error' in storeResponse) {
      contextStore.setShowSpinner(false);
      return console.log(storeResponse.error.message);
    }
    contextStore.setShowSpinner(false);
  };
  const onClickDeleteStore = async () => {
    contextStore.setShowSpinner(true);
    const deleteStoreResponse: IDeleteResponse | IErrorResponse =
      await dispatch(actions.deleteStore, {}, contextStore.token, { id });
    if ('error' in deleteStoreResponse) {
      contextStore.setShowSpinner(false);
      return console.log(deleteStoreResponse.error.message);
    }
    setContextStore((past: any) => {
      return {
        ...past,
        store: {} as IStoreResponse,
      };
    });
    contextStore.socket.emit('leaveStoreLogsRoom', [id]);
    contextStore.setShowSpinner(false);
    navigate(`/dashboard/0`);
  };
  useEffect(() => {
    (async () => {
      if (id !== '0') {
        if (contextStore.socket.connected) {
          contextStore.socket.emit('joinStoreLogsRoom', [id]);
          console.log('Socket Joined Store Room');
          contextStore.socket.on('logs', (log: ILogResponse) => {
            console.log('log received');
            console.log(log);
            const vLogs = logsRef.current;
            vLogs.push(log);
            setLogs(vLogs);
          });
        }
        const logs: Array<ILogResponse> | IErrorResponse = await dispatch(
          actions.getLogs,
          {},
          contextStore.token,
          { storeId: id }
        );
        if ('error' in logs) {
          return console.log(logs.error.message);
        }
        logsRef.current = logs;
        console.log(logsRef.current);
        setContextStore((past: any) => {
          return {
            ...past,
            logs,
          };
        });
      }
      const storesResponse: Array<IStoreResponse> | IErrorResponse =
        await dispatch(actions.getStores, {}, contextStore.token);
      if ('error' in storesResponse) {
        return console.log(storesResponse.error.message);
      }
      setStores(storesResponse);
    })();
    return () => {
      if (id !== '0') {
        contextStore.socket.emit('leaveStoreLogsRoom', [id]);
      }
      contextStore.socket.off('logs');
      setContextStore((past: any) => {
        return {
          ...past,
          store: {} as IStoreResponse,
        };
      });
    };
  }, [id]);
  useEffect(() => {
    if (logsRef.current.length > 0) {
      if (logsRef.current[logsRef.current.length - 1].type === 'update') {
        return setContextStore((past: any) => {
          return {
            ...past,
            logs: logsRef.current,
            storeUpdate: contextStore.storeUpdate + 1,
          };
        });
      }
      setContextStore((past: any) => {
        return {
          ...past,
          logs: logsRef.current,
        };
      });
    }
  }, [logsUpdate]);

  return (
    <Fragment>
      <div className='header'>
        <div className='header__logo'>
          <span className='accent'>Order</span>
          <span className='secondary'>Bot</span>
        </div>
        <div className='header__activeStore'>
          {contextStore.store.name ? contextStore.store.name : 'Active Store'}
        </div>
        <div className='header__storeDropdown'>
          <div
            className='header__storeDropdownButton'
            onClick={() => {
              setExpandDropdown(!expandDropdown);
            }}>
            <div>Available Shops</div>

            {expandDropdown ? (
              <ArrowDropUpIcon className='large__svg' />
            ) : (
              <ArrowDropDownIcon className='large__svg' />
            )}
          </div>
          {expandDropdown && (
            <div className='header__availableStoreListContainer'>
              <div className='header__availableStoreList'>
                {stores.map((store) => (
                  <>
                    <div>{store.name}</div>
                    <div
                      className='button bg-blue'
                      onClick={() => {
                        onSelectStore(store._id);
                      }}>
                      Select
                    </div>
                    {/* <div className='button bg-danger'>Delete</div> */}
                  </>
                ))}
              </div>
              <div className='header__availableStoreButtonContainer'>
                <div
                  className='header__availableStoreButton button bg-blue'
                  onClick={() => {
                    setShowModal(true);
                  }}>
                  Add Store
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='header__buttons'>
          <div className='header__buttons-span'>
            {contextStore.store._id && (
              <div
                className='button bg-danger mlr-1'
                onClick={onClickDeleteStore}>
                Delete
              </div>
            )}

            <div className='button bg-blue mlr-1' onClick={onClickProductGroup}>
              All Products
            </div>
            <div className='button bg-blue mlr-1' onClick={onClickProductGroupOrderDate}>
              {`Products(Order Date)`}
            </div>
          </div>
          <div style={{ position: 'absolute', right: '10px', display: 'flex' }}>
            {contextStore.store._id && (
              <div className='button bg-blue mlr-1' onClick={onClickReinitiate}>
                Re Initiate
              </div>
            )}
            <div className='button bg-danger mlr-1' onClick={onClickLogout}>
              Logout
            </div>
          </div>
        </div>
      </div>

      {showModal && <AddStoreModal setShowModal={setShowModal} />}
    </Fragment>
  );
};
