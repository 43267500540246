import { Fragment } from 'react';
import { Signup } from '../components/auth/SignUp/Signup.component';

const SignupPage = () => {
    return (
        <Fragment>
            <Signup />
        </Fragment>
    )
}
export default SignupPage