import { actions } from "./actions";
import axios from "axios";
import { urls } from "./urls";

const {baseUrlBotApi, baseUrlRestApi} = urls
export const dispatch = async (action: string, body?: Object, token?: string, headerParams?: any) => {
    try{
        let response = {} as any
    let axiosOptions = {} as any
    switch(action){
        case actions.signup: 
            axiosOptions =  getAxiosOptions("POST", `${baseUrlRestApi}/auth/signup`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.login:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/auth/login`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getUser:
            axiosOptions = getAxiosOptions("GET",  `${baseUrlRestApi}/user`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getStore:
            axiosOptions = getAxiosOptions("GET", `${baseUrlRestApi}/store/${headerParams.id}`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getLogs:
            axiosOptions  = getAxiosOptions("GET", `${baseUrlRestApi}/logs/store/${headerParams.storeId}`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.createStore:
            axiosOptions = getAxiosOptions("POST", `${baseUrlBotApi}/createStore`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getStores:
            axiosOptions = getAxiosOptions("GET", `${baseUrlRestApi}/store`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getStoreProducts:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/product/store/${headerParams.storeId}`, body, token)
            response =  await axios(axiosOptions)
            return response.data
        case actions.createProductGroup:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/productGroup`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getStoreProductGroups:
            axiosOptions = getAxiosOptions("GET", `${baseUrlRestApi}/productGroup/store/${headerParams.storeId}`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.editProduct:
            axiosOptions = getAxiosOptions("PUT", `${baseUrlRestApi}/product/${headerParams.id}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.deleteProductGroup:
            axiosOptions = getAxiosOptions("DELETE", `${baseUrlRestApi}/productGroup/${headerParams.id}`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.reInitiateStore:
            axiosOptions = getAxiosOptions("PUT", `${baseUrlBotApi}/reinitiateStore/${headerParams.id}`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.deleteStore: 
            axiosOptions = getAxiosOptions("DELETE", `${baseUrlBotApi}/deleteStore/${headerParams.id}`, {}, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.editProductGroup:
            axiosOptions = getAxiosOptions("PUT", `${baseUrlRestApi}/productGroup/id/${headerParams.id}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getProductGroupProducts:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/product/productGroup/${headerParams.productGroupId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.addProduct: 
            axiosOptions = getAxiosOptions("PUT", `${baseUrlRestApi}/productGroup/addProduct/${headerParams.id}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.removeProduct:
            axiosOptions = getAxiosOptions("PUT", `${baseUrlRestApi}/productGroup/removeProduct/${headerParams.productId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getProduct:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/product/${headerParams.id}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.addProducts:
            axiosOptions = getAxiosOptions("PUT", `${baseUrlRestApi}/productGroup/addProducts/${headerParams.id}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getAllProductGroupProducts:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/product/get/all`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.clearLog:
            axiosOptions = getAxiosOptions("DELETE", `${baseUrlRestApi}/logs/store/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getAnalytics:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/product/analytics/productGroup`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getAnalyticsProduct: 
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/product/analytics/product`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getAnalyticsAll:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/product/analytics/all`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.getShipmentProviders:
            axiosOptions = getAxiosOptions("GET", `${baseUrlRestApi}/store/get/shipmentproviders/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.startPendingBot:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/store/start/pendingbot/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.stopPendingBot:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/store/stop/pendingbot/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.addReadyToShipBotEmail:
            axiosOptions = getAxiosOptions("PUT", `${baseUrlRestApi}/store/readytoshipbot/add/email/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.deleteReadyToShipBotEmail:
            axiosOptions = getAxiosOptions("DELETE", `${baseUrlRestApi}/store/readytoshipbot/delete/email/${headerParams.storeId}/${headerParams.email}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.addXlsxBotEmail:
            axiosOptions = getAxiosOptions("PUT", `${baseUrlRestApi}/store/xlsxbot/add/email/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.deleteXlsxBotEmail:
            axiosOptions = getAxiosOptions("DELETE", `${baseUrlRestApi}/store/xlsxbot/delete/email/${headerParams.storeId}/${headerParams.email}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.startReadyToShipBot:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/store/start/readytoshipbot/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
        case actions.stopReadyToShipBot:
            axiosOptions = getAxiosOptions("POST", `${baseUrlRestApi}/store/stop/readytoshipbot/${headerParams.storeId}`, body, token)
            response = await axios(axiosOptions)
            return response.data
    }
    }   
    catch(err: any){
        console.log(err)
        return err.response.data
    }
}
const getAxiosOptions = (method: string, url: string, body?: Object, token?: string) => {
    const headers = {
        "x-auth-token": token ? token : "",
        "Content-Type": "application/json",
    };
    switch (method) {
        case "GET":
            return {
                method: "GET",
                url,
                headers,
            };
            break;
        case "POST":
            return {
                method: "POST",
                url,
                headers,
                data: body,
            };
            break;
        case "PUT":
            return {
                method: "PUT",
                url,
                headers,
                data: body,
            };
            break;
        case "DELETE":
            return {
                method: "DELETE",
                url,
                headers,
            };
            break;
    }
};
