import { lazy, useContext, useState, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ContextStore from "../hooks/ContextStore";
import SignupPage from "../pages/Signup";
import UiPaths from "../paths/uiPaths";
import ProductGroupPage from "../pages/ProductGroup"
const DashboardPage = lazy(() => import("../pages/DashBoard"));
const LoginPage = lazy(() => import("../pages/Login"));
const NotFoundPage = lazy(() => import("../pages/NotFoundPage"));

const RouteProvider = () => {
    const { contextStore, setContextStore } = useContext(ContextStore);
    const { Login, Dashboard, Signup, ProductGroup } = UiPaths;
    return (
        <BrowserRouter>
            {!contextStore.user._id ? (
                <Routes>
                    <Route path={Login} element={<LoginPage />} />
                    <Route path={Signup} element={<SignupPage />}/>
                    <Route path="/*" element={<Navigate to={Login} />} />
                </Routes>
            ) : (
                <Routes> 
                  <Route path={ProductGroup} element={ <ProductGroupPage />}/>
                  <Route path={Dashboard} element={ <DashboardPage />}/>
                  <Route path='/*' element={<Navigate to={"/dashboard/0"} replace/>} />
                </Routes>
            )}
            {/* <Routes>
        <Route path={Login} element={<LoginPage />} />
        <Route
                  path={Signup}
                  element={
                    <>
                      <SignupPage />
                    </>
                  }
                />
        <Route
          path='/*'
          element={
            !isLoggedIn ? (
              <Navigate
                to={Login}
                // state={{ pathname: location.pathname }}
                replace
              />
            ) : (
              <Routes>
                <Route path='/*' element={<Navigate
                to={"/dashboard/0"}
                // state={{ pathname: location.pathname }}
                replace
              />} />
                <Route
                  path={Dashboard}
                  element={
                    <>
                      <DashboardPage />
                    </>
                  }
                />
                
              </Routes>
            )
          }
        />
      </Routes> */}
        </BrowserRouter>
    );
};

export default RouteProvider;
