import { FC, Fragment, useState, useEffect, useContext } from 'react';
import './Table.component.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Analytics } from '../Analytics/Analytics.component';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandIcon from '@mui/icons-material/Expand';
import SortIcon from '@mui/icons-material/Sort';
import { EditProductModal } from '../../../modals';
import { Pagination } from '../../common';
import { useParams } from 'react-router-dom';
import ContextStore from '../../../hooks/ContextStore';
import { IProductGroupTableData } from '../../../interfaces/formData/ProductGroupTableData.interace';
import { IProductResponse } from '../../../interfaces/responses/ProductResponse.interface';
import { IProductGroupResponse } from '../../../interfaces/responses/ProductGroupResponse.interface';
import { IOverviewTableValues } from '../../../interfaces/formData/OverViewTableValues.interface';
import { IDeleteResponse } from '../../../interfaces/responses/DeleteResponse.interface';
import { IErrorResponse } from '../../../interfaces/responses/ErrorResponse.interface';
import { dispatch } from '../../../dispatch/dispatch';
import { actions } from '../../../dispatch';
import { ProductTableHead } from './ProductTableHead.component';
import { ISortedField } from '../../../interfaces/table/sortedField';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import { IAnalyticsResponse } from '../../../interfaces/responses/AnalyticsResponse.interface';
import * as XLSX from 'xlsx';
import FileSaver from "file-saver"
export const ProductTable: FC = () => {
  const [tableData, setTableData] = useState<Array<IProductGroupTableData>>([]);
  const [filteredTableData, setFilteredTableData] = useState<
    Array<IProductGroupTableData>
  >([]);
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(false);
  const noSortedField: ISortedField = {
    delivered: false,
    canceled: false,
    returned: false,
    failed: false,
    totalOrders: false,
    totalProfit: false,
    profitPerProduct: false,
    deliveredPercentage: false,
    roi: false,
    loss: false,
    groupName: false,
    buyingRate: false,
    stockAvailable: false,
    progressDeliveredOrders: false,
    progressProfit: false,
    progressProfitPPP: false,
    shippedOrders: false,
    readyToShipOrders: false,
    pendingOrders: false,
    progressTotalOrders: false
  }
  const [sortedField, setSortedField] = useState<ISortedField>(noSortedField);
  useEffect(() => {
    const vTableData = [...tableData];
    if (sortedField.delivered) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.deliveredOrders - b.deliveredOrders;
          }
          return b.deliveredOrders - a.deliveredOrders;
        })
      );
    }
    if (sortedField.canceled) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.canceledOrders - b.canceledOrders;
          }
          return b.canceledOrders - a.canceledOrders;
        })
      );
    }
    if (sortedField.returned) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.returnedOrders - b.returnedOrders;
          }
          return b.returnedOrders - a.returnedOrders;
        })
      );
    }
    if (sortedField.failed) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.failedOrders - b.failedOrders;
          }
          return b.failedOrders - a.failedOrders;
        })
      );
    }
    if (sortedField.totalOrders) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.totalOrders - b.totalOrders;
          }
          return b.totalOrders - a.totalOrders;
        })
      );
    }
    if (sortedField.totalProfit) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.totalProfit - b.totalProfit;
          }
          return b.totalProfit - a.totalProfit;
        })
      );
    }
    if (sortedField.profitPerProduct) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.profitPerProduct - b.profitPerProduct;
          }
          return b.profitPerProduct - a.profitPerProduct;
        })
      );
    }
    if (sortedField.deliveredPercentage) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.deliveredPercentage - b.deliveredPercentage;
          }
          return b.deliveredPercentage - a.deliveredPercentage;
        })
      );
    }
    if (sortedField.roi) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.roi - b.roi;
          }
          return b.roi - a.roi;
        })
      );
    }
    if (sortedField.loss) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.loss - b.loss;
          }
          return b.loss - a.loss;
        })
      );
    }
    if (sortedField.buyingRate) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.buyingRate - b.buyingRate;
          }
          return b.buyingRate - a.buyingRate;
        })
      );
    }
    if (sortedField.stockAvailable) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.stockAvailable - b.stockAvailable;
          }
          return b.stockAvailable - a.stockAvailable;
        })
      );
    }
    if (sortedField.progressDeliveredOrders) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.progressDeliveredOrders - b.progressDeliveredOrders;
          }
          return b.progressDeliveredOrders - a.progressDeliveredOrders;
        })
      );
    }
    if (sortedField.progressProfit) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.progressProfit - b.progressProfit;
          }
          return b.progressProfit - a.progressProfit;
        })
      );
      
    }
    if (sortedField.progressProfitPPP) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.progressProfitPPP - b.progressProfitPPP;
          }
          return b.progressProfitPPP - a.progressProfitPPP;
        })
      );
      
    }
    if (sortedField.pendingOrders) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.pendingOrders - b.pendingOrders;
          }
          return b.pendingOrders - a.pendingOrders;
        })
      );
      
    }
    if (sortedField.shippedOrders) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.shippedOrders - b.shippedOrders;
          }
          return b.shippedOrders - a.shippedOrders;
        })
      );
      
    }
    if (sortedField.readyToShipOrders) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.readyToShipOrders - b.readyToShipOrders;
          }
          return b.readyToShipOrders - a.readyToShipOrders;
        })
      );
      
    }
    if (sortedField.progressTotalOrders) {
      setTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.progressTotalOrders - b.progressTotalOrders;
          }
          return b.progressTotalOrders - a.progressTotalOrders;
        })
      );
      
    }

    
  }, [ascendingOrder, sortedField, tableData]);
  const { contextStore, setContextStore } = useContext(ContextStore);
  const { id , type} = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  let totalPages = Math.floor(filteredTableData.length / 40) + 1;
  const [selectedProductGroup, setSelectedProductGroup] =
    useState<IProductGroupTableData>({} as IProductGroupTableData);
  const onClickEdit = (productGroup: IProductGroupTableData) => {
    setSelectedProductGroup(productGroup);
    setShowModal(true);
  };
  const [searchString, setSearchString] = useState<string>('');
  const [tableValues, setTableValues] = useState<IOverviewTableValues>({
    deliveredOrders: 0,
    canceledOrders: 0,
    returnedOrders: 0,
    deliveredPercentage: 0,
    totalPayout: 0,
    totalBuyingRate: 0,
    totalTransportFees: 0,
    totalStorageFees: 0,
    totalPackageFees: 0,
    totalProfit: 0,
    roi: 0,
    profitPerProduct: 0,
    failedOrders: 0,
    totalOrders: 0,
    totalLoss: 0,
    progressDeliveredOrders: 0,
    progressProfit: 0,
    shippedOrders: 0,
    pendingOrders: 0,
    readyToShipOrders: 0,
    progressTotalOrders: 0
  });
  const vTableData = [
    {
      name: 'Number of orders delivered',
      value: tableValues.deliveredOrders,
    },
    {
      name: 'Number of orders canceled',
      value: tableValues.canceledOrders,
    },
    {
      name: 'Number of orders returned',
      value: tableValues.returnedOrders,
    },
    { name: 'Number of order failed', value: tableValues.failedOrders },
    {
      name: 'Percentage or orders Delivered',
      value: tableValues.deliveredPercentage.toFixed(0),
    },
    { name: 'Total Orders', value: tableValues.totalOrders },
    { name: 'Total Payout', value: tableValues.totalPayout.toFixed(2) },
    {
      name: 'Total Product Buying Cost',
      value: tableValues.totalBuyingRate.toFixed(2),
    },
    {
      name: 'Total Product Transport Fees',
      value: tableValues.totalTransportFees.toFixed(2),
    },
    {
      name: 'Total Product Storage Fees',
      value: tableValues.totalStorageFees.toFixed(2),
    },
    {
      name: 'Total Product Package Fees',
      value: tableValues.totalPackageFees.toFixed(2),
    },
    { name: 'Total Profit', value: tableValues.totalProfit.toFixed(2) },
    { name: 'roi(%)', value: tableValues.roi.toFixed(0) },
    { name: 'Profit Per Product', value: tableValues.profitPerProduct },
    { name: 'Total Loss', value: tableValues.totalLoss },
  ];
  const onExpandRow = async (
    productGroup: IProductGroupTableData,
    index: number
  ) => {
    expandRowNo === index + 1
      ? setExpandRowNo(undefined)
      : setExpandRowNo(index + 1);
    setTableValues({
      deliveredOrders: productGroup.deliveredOrders,
      canceledOrders: productGroup.canceledOrders,
      returnedOrders: productGroup.returnedOrders,
      deliveredPercentage: productGroup.deliveredPercentage,
      totalPayout: productGroup.totalPayout,
      totalBuyingRate: productGroup.totalBuyingRate,
      totalTransportFees: productGroup.totalTransportFees,
      totalStorageFees: productGroup.totalStorageFees,
      totalPackageFees: productGroup.totalPackageFees,
      totalProfit: productGroup.totalProfit,
      roi: productGroup.roi,
      profitPerProduct: productGroup.profitPerProduct,
      failedOrders: productGroup.failedOrders,
      totalOrders:
        productGroup.deliveredOrders +
        productGroup.returnedOrders +
        productGroup.canceledOrders +
        productGroup.failedOrders,
      totalLoss: productGroup.loss,
    progressDeliveredOrders: productGroup.progressDeliveredOrders,
    progressProfit: productGroup.progressProfit,
    shippedOrders: productGroup.shippedOrders,
    pendingOrders: productGroup.pendingOrders,
    readyToShipOrders: productGroup.readyToShipOrders,
    progressTotalOrders: productGroup.progressTotalOrders
    });
    if (!(expandRowNo === index + 1)) {
      contextStore.setShowSpinner(true);
      const analyticsResponse: Analytics | IErrorResponse =
        await dispatch(
          actions.getAnalytics,
          {
            fromDate: contextStore.date.fromDate.toISOString(),
            toDate: contextStore.date.toDate.toISOString(),
            productGroupId: productGroup._id,
            storeId: contextStore.store._id ? contextStore.store._id : '0',
            type
          },
          contextStore.token
        );
      if ('error' in analyticsResponse) {
        contextStore.setShowSpinner(false);
        return console.log(analyticsResponse.error.message);
      }
      console.log(analyticsResponse);
      contextStore.setShowSpinner(false);
      setGraphData(analyticsResponse);
    }
  };
  const onClickDelete = async (productGroupId: string) => {
    contextStore.setShowSpinner(true);
    const productGroupDeleteResponse: IDeleteResponse | IErrorResponse =
      await dispatch(actions.deleteProductGroup, {}, contextStore.token, {
        id: productGroupId,
      });
    if ('error' in productGroupDeleteResponse) {
      contextStore.setShowSpinner(false);
      return console.log(productGroupDeleteResponse.error.message);
    }
    const productGroupProductsResponse:
      | Array<IProductResponse>
      | IErrorResponse = await dispatch(
      actions.getProductGroupProducts,
      {
        fromDate: contextStore.date.fromDate,
        toDate: contextStore.date.toDate,
      },
      contextStore.token,
      { productGroupId: productGroupId }
    );
    if ('error' in productGroupProductsResponse) {
      return console.log(productGroupProductsResponse.error.message);
    }
    console.log(productGroupProductsResponse);
    const vProducts = contextStore.products;
    productGroupProductsResponse.map((product) => {
      const index = vProducts.findIndex(
        (vProduct) => vProduct._id === product._id
      );
      if (index !== -1) {
        vProducts[index] = product;
      }
    });
    console.log(productGroupDeleteResponse.status);
    let vProductGroups = contextStore.productGroups;
    vProductGroups = vProductGroups.filter(
      (productGroup) => productGroup._id !== productGroupId
    );
    contextStore.overviewTrigger(vProducts);
    contextStore.productTrigger(vProducts);
    contextStore.productGroupTrigger(vProductGroups, vProducts);
    setContextStore((past: any) => {
      return {
        ...past,
        productGroups: vProductGroups,
        products: vProducts,
      };
    });
    contextStore.setShowSpinner(false);
  };
  const updateTableData = (
    productGroups: Array<IProductGroupResponse>,
    products: Array<IProductResponse>
  ) => {
    let vTableData = [] as Array<IProductGroupTableData>;
    vTableData = productGroups.map((productGroup) => {
      let vTableEntry = {} as IProductGroupTableData;
      vTableEntry.name = productGroup.name;
      vTableEntry._id = productGroup._id;
      vTableEntry.skus = [] as Array<string>;
      vTableEntry.deliveredOrders = 0;
      vTableEntry.canceledOrders = 0;
      vTableEntry.returnedOrders = 0;
      vTableEntry.shippedOrders = 0
      vTableEntry.pendingOrders = 0
      vTableEntry.readyToShipOrders = 0
      vTableEntry.deliveredPercentage = 0;
      vTableEntry.totalPayout = 0;
      vTableEntry.totalBuyingRate = 0;
      vTableEntry.totalTransportFees = 0;
      vTableEntry.totalStorageFees = 0;
      vTableEntry.totalPackageFees = 0;
      vTableEntry.totalProfit = 0;
      vTableEntry.roi = 0;
      vTableEntry.totalOrders = 0;
      vTableEntry.failedOrders = 0;
      vTableEntry.profitPerProduct = 0;
      vTableEntry.loss = 0;
      vTableEntry.progressDeliveredOrders = 0
      vTableEntry.progressProfit = 0
      vTableEntry.progressProfitPPP = 0
      vTableEntry.progressTotalOrders = 0
      vTableEntry.buyingRate = productGroup.buyingRate;
      vTableEntry.packageFees = productGroup.packageFees;
      vTableEntry.transportFees = productGroup.transportFees;
      vTableEntry.storageFees = productGroup.storageFees;
      vTableEntry.stockAvailable = productGroup.stockAvailable
      let vProducts = products.filter((product) => {
        if (product.productGroup) {
          return product.productGroup._id === productGroup._id;
        }
        return false;
      });
      let progressDeliveredOrdersBefore = 0
      let progressProfitBefore = 0
      let progressDeliveredOrdersAfter = 0
      let progressProfitAfter = 0
      let progressTotalOrdersBefore = 0
      let progressTotalOrdersAfter = 0
      vProducts.map((product) => {
        vTableEntry.skus.push(product.Sku);
        vTableEntry.deliveredOrders += product.deliveredOrders;
        vTableEntry.canceledOrders += product.canceledOrders;
        vTableEntry.returnedOrders += product.returnedOrders;
        vTableEntry.pendingOrders += product.pendingOrders;
        vTableEntry.shippedOrders += product.shippedOrders
        vTableEntry.readyToShipOrders += product.readyToShipOrders
        vTableEntry.deliveredPercentage += product.deliveredPercentage;
        vTableEntry.totalPayout += product.totalPayout;
        vTableEntry.totalBuyingRate += product.totalBuyingRate;
        vTableEntry.totalTransportFees += product.totalTransportFees;
        vTableEntry.totalStorageFees += product.totalStorageFees;
        vTableEntry.totalPackageFees += product.totalPackageFees;
        vTableEntry.totalProfit += product.totalProfit;
        vTableEntry.roi += product.roi;
        progressDeliveredOrdersBefore += product.progressDeliveredOrdersBefore
        progressDeliveredOrdersAfter += product.progressDeliveredOrdersAfter
        progressProfitBefore += product.progressProfitBefore
        progressProfitAfter += product.progressProfitAfter
        progressTotalOrdersBefore += product.progressTotalOrdersBefore
        progressTotalOrdersAfter += product.progressTotalOrdersAfter
        vTableEntry.totalOrders +=
          product.deliveredOrders +
          product.returnedOrders +
          product.canceledOrders +
          product.failedOrders + product.shippedOrders + product.pendingOrders + product.readyToShipOrders;
        vTableEntry.failedOrders += product.failedOrders;
        vTableEntry.profitPerProduct += product.profitPerProduct;
        vTableEntry.loss += product.loss;
        
      });
      vTableEntry.deliveredPercentage = parseFloat(
        (
          (vTableEntry.deliveredOrders /
            (vTableEntry.deliveredOrders + vTableEntry.returnedOrders)) *
          100
        ).toFixed(2)
      );
      vTableEntry.roi = parseFloat(
        ((vTableEntry.totalProfit / vTableEntry.totalBuyingRate) * 100).toFixed(
          2
        )
      );
      vTableEntry.profitPerProduct = parseFloat(
        (vTableEntry.totalProfit / vTableEntry.deliveredOrders).toFixed(2)
      );
      if(progressDeliveredOrdersBefore != 0) {
        vTableEntry.progressDeliveredOrders = parseFloat((((progressDeliveredOrdersAfter - progressDeliveredOrdersBefore)/progressDeliveredOrdersBefore) * 100).toFixed(2))
    }
    if(progressProfitBefore != 0){
        vTableEntry.progressProfit = parseFloat((((progressProfitAfter - progressProfitBefore)/progressProfitBefore) * 100).toFixed(2))
    }
    if(progressProfitBefore != 0 && progressDeliveredOrdersBefore != 0 && progressProfitAfter != 0 && progressDeliveredOrdersAfter != 0){
      vTableEntry.progressProfitPPP = parseFloat((((progressProfitAfter/progressDeliveredOrdersAfter - progressProfitBefore/progressDeliveredOrdersBefore)/(progressProfitBefore/progressDeliveredOrdersBefore)) * 100).toFixed(2))
    }
    if(progressTotalOrdersBefore != 0){
      vTableEntry.progressTotalOrders = parseFloat((((progressTotalOrdersAfter - progressTotalOrdersBefore)/progressTotalOrdersBefore) * 100).toFixed(2))
    }
      return vTableEntry;
    });
    setTableData(vTableData);
    setExpandRowNo(undefined);
  };
  useEffect(() => {
    console.log('Set Up Use Effect');
    setContextStore((past: any) => {
      return { ...past, productGroupTrigger: updateTableData, onClickExport };
    });
  }, []);
  useEffect(() => {
    setFilteredTableData(
      tableData.filter((data) => {
        let status = false;
        if (data.name.toLowerCase().includes(searchString.toLowerCase())) {
          status = true;
        }
        if (!status) {
          for (const sku of data.skus) {
            if (sku.toLowerCase().includes(searchString.toLowerCase())) {
              status = true;
            }
            if (status) {
              break;
            }
          }
        }
        return status;
      })
    );
  }, [tableData, searchString]);
  const onClickReset = () => {
    setSearchString('');
    updateTableData(contextStore.productGroups, contextStore.products);
    setSortedField(noSortedField);
  };
  interface Analytics {
    analytics: Array<IAnalyticsResponse>
    stockEstimation: number
  }
  const [expandRowNo, setExpandRowNo] = useState<number | undefined>();
  const [graphData, setGraphData] = useState<Analytics>({} as Analytics);
  // const graphData = [
  //     {
  //         day: "1",
  //         totalProfit: 3,
  //         deliveredOrders: 2,
  //     },
  //     {
  //         day: "2",
  //         totalProfit: 6,
  //         deliveredOrders: 9,
  //     },

  //     {
  //         day: "3",
  //         totalProfit: 4,
  //         deliveredOrders: 6,
  //     },
  //     {
  //         day: "4",
  //         totalProfit: 6,
  //         deliveredOrders: 4,
  //     },
  //     {
  //         day: "5",
  //         totalProfit: 3,
  //         deliveredOrders: 6,
  //     },
  // ];
  const [legend, setLegend] = useState<string[]>(['totalProfit']);
  const selectLegend = (value: string) => {
    setLegend([...legend, value]);
  };

  const deselectLegend = (value: string) => {
    const filteredOptions = legend.filter((l) => l !== value);
    setLegend([...filteredOptions]);
  };

  const handleLegend = (value: string) => {
    legend.some((l) => l === value)
      ? deselectLegend(value)
      : selectLegend(value);
  };
  const [cssClass, setCssClass] = useState({
    tableContainer: "tableAnalysis__container"
  })
  useEffect(() => {
    if(type === "order"){
      setCssClass({...cssClass, tableContainer: "tableAnalysis__container__green"})
    }
  }, [type])
  const onClickExport = () => {
    let fileName
    if(type === "order"){
      fileName = "Order"
    }
    else if(type === "update"){
      fileName = "Update"
    }
    else {
      fileName = contextStore.store.name
    }
    console.log(contextStore.products.length)
    if(contextStore.products.length > 0){
      console.log("Trying to export 3")
      const skuSheetData = contextStore.products.map(product => {
        return {
          "SKU":product.Sku,
      "Name":product.name,
      "Store":product.store ? product.store.name: contextStore.store.name,
      "Product Group":product.productGroup? product.productGroup.name: "",
      "Total Orders":product.shippedOrders + product.canceledOrders + product.readyToShipOrders + product.pendingOrders + product.deliveredOrders + product.failedOrders + product.returnedOrders,
      "Progress(Total Orders)": `${product.progressTotalOrders}%`,
      "Delivered Orders":product.deliveredOrders,
      "Progress(Delivered)": `${product.progressDeliveredOrders}%`,
      "Profit Per Product": `${product.profitPerProduct} MAD`,
      "Progress(Profit Per Product)": `${product.progressProfitPPP}%`,
      "Total Profit": `${product.totalProfit} MAD`,
      "Progress (Total Profit)": `${product.progressProfit}`,   
      "Buying Price": product.buyingRate,
      "Delivered Percentage": `${product.deliveredPercentage}%`,
      "ROI": `${product.roi}%`,
      "Returned Orders":product.returnedOrders,
      "Loss": `${product.loss} MAD`,
      "Pending Orders":product.pendingOrders,
      "Ready To Ship Orders":product.readyToShipOrders,
      "Shipped Orders":product.shippedOrders,
      "Canceled Orders":product.canceledOrders,
      "Failed Orders":product.failedOrders,
      
        }
      })
      const productSheetData = tableData.map(product => {
        return {
      "Name":product.name,
      "SKUs": product.skus,
      "Total Orders": product.totalOrders,
      "Progress(Total Orders)": `${product.progressTotalOrders}%`,
      "Delivered Orders":product.deliveredOrders,
      "Progress(Delivered)": `${product.progressDeliveredOrders}%`,
      "Profit Per Product": `${product.profitPerProduct} MAD`,
      "Progress(Profit Per Product)": `${product.progressProfitPPP}%`,
      "Total Profit": `${product.totalProfit} MAD`,
      "Progress (Total Profit)": `${product.progressProfit}`,   
      "Buying Price": product.buyingRate,
      "Delivered Percentage": `${product.deliveredPercentage}%`,
      "ROI": `${product.roi}%`,
      "Returned Orders":product.returnedOrders,
      "Loss": `${product.loss} MAD`,
      "Pending Orders":product.pendingOrders,
      "Ready To Ship Orders":product.readyToShipOrders,
      "Shipped Orders":product.shippedOrders,
      "Canceled Orders":product.canceledOrders,
      "Failed Orders":product.failedOrders,
        }
      })
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws1 = XLSX.utils.json_to_sheet(skuSheetData)
      const ws2 = XLSX.utils.json_to_sheet(productSheetData)
      const wb = {Sheets: {"SKU": ws1, "Product": ws2}, SheetNames: ["SKU", "Product"]}
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
  return (
    <Fragment>
      <div className='tableAnalysis__nameContainer'>
        <div className='tableAnalysis__name'>
          Manage Products <button onClick={onClickReset}>Reset</button>
        </div>
        <div
          className='analytics__createGroupButton button bg-blue text-white'
          onClick={() => {
            onClickExport()}}>
          Export
        </div>
      </div>
      <div className={`${cssClass.tableContainer}`}>
        <div className='tableAnalysis__scroll'>
          <Table className='tableAnalysis'>
            <ProductTableHead
              ascendingOrder={ascendingOrder}
              setAscendingOrder={setAscendingOrder}
              sortedField={sortedField}
              setSortedField={setSortedField}
              searchString={searchString}
              setSearchString={setSearchString}
            />
            <Tbody className='tableAnalysis__row'>
              {filteredTableData &&
                filteredTableData.map(
                  (data, index) =>
                    index < pageNo * 40 &&
                    index >= (pageNo - 1) * 40 && (
                      <Fragment key={index}>
                        <Tr>
                          <Td className='tableAnalysis__data'>{data.name}</Td>
                          <Td className='tableAnalysis__data'>
                            {data.skus.map((sku) => {
                              return `${sku}; `;
                            })}
                          </Td>
                          <Td className='tableAnalysis__data'>
                            <div className='actionButton__span'>
                              <span
                                className='table__span'
                                onClick={() => {
                                  onExpandRow(data, index);
                                }}>
                                <ExpandIcon />
                              </span>
                              <span
                                className='table__span'
                                onClick={() => {
                                  onClickEdit(data);
                                }}>
                                <EditIcon />
                              </span>
                              <span
                                className='table__span'
                                onClick={() => {
                                  onClickDelete(data._id);
                                }}>
                                <DeleteIcon
                                  style={{
                                    color: '#ff4d4d',
                                  }}
                                />
                              </span>
                            </div>
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.totalOrders}
                          </Td>
                          {data.progressTotalOrders < 0 ? 
                            <Td className='tableAnalysis__data' >
                              <p style={{color: "red"}}>{data.progressTotalOrders.toFixed(0)} %</p>
                            </Td>
                            :
                           <Td className='tableAnalysis__data'>
                            <p style={{color: "green"}}>{data.progressTotalOrders.toFixed(0)} %</p>
                          </Td>  
                          }
                                                    <Td className='tableAnalysis__data'>
                            {data.deliveredOrders}
                          </Td>
                            {data.progressDeliveredOrders < 0 ? 
                            <Td className='tableAnalysis__data' >
                              <p style={{color: "red"}}>{data.progressDeliveredOrders.toFixed(0)} %</p>
                            </Td>
                            :
                           <Td className='tableAnalysis__data'>
                            <p style={{color: "green"}}>{data.progressDeliveredOrders.toFixed(0)} %</p>
                          </Td>  
                          }
                                                    <Td className='tableAnalysis__data'>
                            {data.profitPerProduct.toFixed(2)} MAD
                          </Td>
                          {data.progressProfitPPP < 0 ? 
                            <Td className='tableAnalysis__data' >
                              <p style={{color: "red"}}>{data.progressProfitPPP.toFixed(0)} %</p>
                            </Td>
                            :
                           <Td className='tableAnalysis__data'>
                            <p style={{color: "green"}}>{data.progressProfitPPP.toFixed(0)} %</p>
                          </Td>  
                          }
                                                    <Td className='tableAnalysis__data'>
                            {data.totalProfit.toFixed(2)} MAD
                          </Td>
                          {data.progressProfit < 0 ? 
                            <Td className='tableAnalysis__data' >
                              <p style={{color: "red"}}>{data.progressProfit.toFixed(0)} %</p>
                            </Td>
                            :
                           <Td className='tableAnalysis__data'>
                            <p style={{color: "green"}}>{data.progressProfit.toFixed(0)} %</p>
                          </Td>  
                          }
                          <Td className='tableAnalysis__data'>
                            {data.buyingRate}
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.stockAvailable}
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.deliveredPercentage &&
                              data.deliveredPercentage.toFixed(0)}{' '}
                            %
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.roi.toFixed(0)} %
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.returnedOrders}
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.loss} MAD
                          </Td>


                          <Td className='tableAnalysis__data'>
                            {data.pendingOrders}
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.readyToShipOrders}
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.shippedOrders}
                          </Td>
                          <Td className='tableAnalysis__data'>
                            {data.canceledOrders}
                          </Td>

                          <Td className='tableAnalysis__data'>
                            {data.failedOrders}
                          </Td>

                        </Tr>
                        {expandRowNo && expandRowNo === index + 1 && (
                          <Tr>
                            <Td className='tableAnalysis__data' colspan='13'>
                              <div className='tableAnalysis__expand'>
                                {vTableData.map((data, index) => (
                                  <>
                                    <div>{data.name}</div>
                                    <div className='text-end'>{data.value}</div>
                                  </>
                                ))}
                              </div>
                              <div className='tableAnalysis__expand_analytics'>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                    margin: '1rem 0rem',
                                  }}>
                                  <div
                                    className={`analytics__legendOutline ${
                                      legend.some((l) => l === 'totalProfit') &&
                                      'bg-blue'
                                    }`}
                                    onClick={() => {
                                      handleLegend('totalProfit');
                                    }}>
                                    Total Profit
                                  </div>
                                  <div
                                    className={`analytics__legendOutline ${
                                      legend.some(
                                        (l) => l === 'deliveredOrders'
                                      ) && 'bg-blue'
                                    }`}
                                    onClick={() => {
                                      handleLegend('deliveredOrders');
                                    }}>
                                    Delivered Orders
                                  </div>
                                  <div
                                    className={`analytics__legendOutline ${
                                      legend.some(
                                        (l) => l === 'returnedOrders'
                                      ) && 'bg-blue'
                                    }`}
                                    onClick={() => {
                                      handleLegend('returnedOrders');
                                    }}>
                                    Returned Orders
                                  </div>
                                  <div
                                    className={`analytics__legendOutline ${
                                      legend.some(
                                        (l) => l === 'failedOrders'
                                      ) && 'bg-blue'
                                    }`}
                                    onClick={() => {
                                      handleLegend('failedOrders');
                                    }}>
                                    Failed Orders
                                  </div>
                                  <div
                                    className={`analytics__legendOutline ${
                                      legend.some(
                                        (l) => l === 'canceledOrders'
                                      ) && 'bg-blue'
                                    }`}
                                    onClick={() => {
                                      handleLegend('canceledOrders');
                                    }}>
                                    Canceled Orders
                                  </div>
                                  <div
                                    className={`analytics__legendOutline ${
                                      legend.some(
                                        (l) => l === 'totalOrders'
                                      ) && 'bg-blue'
                                    }`}
                                    onClick={() => {
                                      handleLegend('totalOrders');
                                    }}>
                                    Total Orders
                                  </div>
                                  <div
            className={`analytics__legendOutline ${
              legend.some((l) => l === 'shippedOrders') && 'bg-blue text-white'
            }`}
            onClick={() => {
              handleLegend('shippedOrders');
            }}>
            Shipped Orders
          </div>
          <div
            className={`analytics__legendOutline ${
              legend.some((l) => l === 'readyToShipOrders') && 'bg-blue text-white'
            }`}
            onClick={() => {
              handleLegend('readyToShipOrders');
            }}>
            RTS Orders
          </div>
          <div
            className={`analytics__legendOutline ${
              legend.some((l) => l === 'pendingOrders') && 'bg-blue text-white'
            }`}
            onClick={() => {
              handleLegend('pendingOrders');
            }}>
            Pending Orders
          </div>
                                </div>
                                <div>Stock Estimation: {graphData.stockEstimation}</div>
                                <ResponsiveContainer width='99%' aspect={7}>
                                  <AreaChart
                                    data={graphData.analytics}
                                    margin={{
                                      top: 0,
                                      right: 0,
                                      left: 0,
                                      bottom: 0,
                                    }}>
                                    {/* <defs>
                <linearGradient
                  id='colortotalProfit'
                  x1='0'
                  y1='0'
                  x2='0'
                  y2='1'>
                  <stop offset='5%' stopColor='#00aeef' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#00aeef' stopOpacity={0} />
                </linearGradient>
              </defs> */}
                                    <XAxis dataKey='date' />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <Tooltip />
                                    {/* <Legend verticalAlign='top' height={40} /> */}

                                    {legend.some(
                                      (l) => l === 'totalProfit'
                                    ) && (
                                      <Area
                                        type='monotone'
                                        dataKey='totalProfit'
                                        // name='New Patient'
                                        stroke='#121212'
                                        fillOpacity={0.5}
                                        fill='#00aeef'
                                      />
                                    )}
                                    {legend.some(
                                      (l) => l === 'deliveredOrders'
                                    ) && (
                                      <Area
                                        type='monotone'
                                        dataKey='deliveredOrders'
                                        // name='Old Patient'
                                        stroke='#121212'
                                        fillOpacity={0.5}
                                        fill='#52b041'
                                      />
                                    )}
                                                                        {legend.some(
                                      (l) => l === 'returnedOrders'
                                    ) && (
                                      <Area
                                        type='monotone'
                                        dataKey='returnedOrders'
                                        // name='Old Patient'
                                        stroke='#121212'
                                        fillOpacity={0.5}
                                        fill='#b04141'
                                      />
                                    )}
                                                                        {legend.some(
                                      (l) => l === 'failedOrders'
                                    ) && (
                                      <Area
                                        type='monotone'
                                        dataKey='failedOrders'
                                        // name='Old Patient'
                                        stroke='#121212'
                                        fillOpacity={0.5}
                                        fill='#b0aa41'
                                      />
                                    )}
                                                                        {legend.some(
                                      (l) => l === 'canceledOrders'
                                    ) && (
                                      <Area
                                        type='monotone'
                                        dataKey='canceledOrders'
                                        // name='Old Patient'
                                        stroke='#121212'
                                        fillOpacity={0.5}
                                        fill='#b07741'
                                      />
                                    )}
                                                                        {legend.some(
                                      (l) => l === 'totalOrders'
                                    ) && (
                                      <Area
                                        type='monotone'
                                        dataKey='totalOrders'
                                        // name='Old Patient'
                                        stroke='#121212'
                                        fillOpacity={0.5}
                                        fill='#b041b0'
                                      />
                                    )}
                                                {legend.some((l) => l === 'pendingOrders') && (
              <Area
                type='monotone'
                dataKey='pendingOrders'
                // name='Old Patient'
                stroke='#121212'
                fillOpacity={0.5}
                fill='#223a78'
              />
            )}
            {legend.some((l) => l === 'shippedOrders') && (
              <Area
                type='monotone'
                dataKey='shippedOrders'
                // name='Old Patient'
                stroke='#121212'
                fillOpacity={0.5}
                fill='#6f2278'
              />
            )}
            {legend.some((l) => l === 'readyToShipOrders') && (
              <Area
                type='monotone'
                dataKey='readyToShipOrders'
                // name='Old Patient'
                stroke='#121212'
                fillOpacity={0.5}
                fill='#78223f'
              />
            )}
                                  </AreaChart>
                                </ResponsiveContainer>
                              </div>
                            </Td>
                          </Tr>
                        )}
                      </Fragment>
                    )
                )}
            </Tbody>
          </Table>
        </div>
        <Pagination
          pageNo={pageNo}
          totalPages={totalPages}
          setPageNo={setPageNo}
        />
      </div>
      {showModal && (
        <EditProductModal
          setShowModal={setShowModal}
          productGroup={selectedProductGroup}
        />
      )}
    </Fragment>
  );
};
