import { useState, Dispatch, SetStateAction, FC, useEffect } from 'react';
import './Table.component.css';
import { Thead, Tr, Th } from 'react-super-responsive-table';
import SortIcon from '@mui/icons-material/Sort';
import { ISortedField } from '../../../interfaces/table/sortedField';
import { useParams } from 'react-router-dom';
interface SkuTableHeadProps {
  ascendingOrder: boolean;
  setAscendingOrder: Dispatch<SetStateAction<boolean>>;
  sortedField: ISortedField;
  setSortedField: Dispatch<SetStateAction<ISortedField>>;
  searchSku: string;
  setSearchSku: Dispatch<SetStateAction<string>>;
  searchProductGroup: string;
  setSearchProductGroup: Dispatch<SetStateAction<string>>;
  searchProductStore: string;
  setSearchProductStore: Dispatch<SetStateAction<string>>;
}
export const SkuTableHead: FC<SkuTableHeadProps> = ({
  ascendingOrder,
  setAscendingOrder,
  sortedField,
  setSortedField,
  searchSku,
  setSearchSku,
  searchProductGroup,
  setSearchProductGroup,
  searchProductStore, 
  setSearchProductStore
}) => {
  const { id, type } = useParams();
  const [cssClass, setClassCss] = useState({
    headerContainer: "tableAnalysis__header"
  })
  const noSortedField: ISortedField = {
    delivered: false,
    canceled: false,
    returned: false,
    failed: false,
    totalOrders: false,
    totalProfit: false,
    profitPerProduct: false,
    deliveredPercentage: false,
    roi: false,
    loss: false,
    groupName: false,
    buyingRate: false,
    stockAvailable: false,
    progressDeliveredOrders: false,
    progressProfit: false,
    progressProfitPPP: false,
    shippedOrders: false,
    readyToShipOrders: false,
    pendingOrders: false,
    progressTotalOrders: false
  }
  useEffect(() => {
    if(type === "order"){
      setClassCss({...cssClass, headerContainer: "tableAnalysis__header__green"})
    }
  },[type])
  const sortedIconClassName: string = ascendingOrder
    ? 'tableAnalysis__headerSortIcon rotate180'
    : 'tableAnalysis__headerSortIcon';

  return (
    <Thead>
      <Tr className='tableAnalysis__row'>
        <Th className={`${cssClass.headerContainer}`}>Select</Th>
        <Th className={`${cssClass.headerContainer}`}>
          SKU
          <input
            type='text'
            placeholder='SKU or Name'
            value={searchSku}
            onChange={(e: any) => {
              setSearchSku(e.target.value);
            }}
          />
        </Th>
        <Th className={`${cssClass.headerContainer} tableAnalysis__nameheader`}>
          Name
        </Th>

        <Th className={`${cssClass.headerContainer}`}>
          Group Name
          <input
            type='text'
            placeholder='Group Name'
            value={searchProductGroup}
            onChange={(e: any) => {
              setSearchProductGroup(e.target.value);
            }}
          />
        </Th>
        {!id && <Th className={`${cssClass.headerContainer}`}>Store
        <input
            type='text'
            placeholder='Store Name'
            value={searchProductStore}
            onChange={(e: any) => {
              setSearchProductStore(e.target.value);
            }}
          />
        </Th>}
        <Th className={`${cssClass.headerContainer}`}>Actions</Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Total Orders</div>{' '}
            <div
              className={`${
                sortedField.totalOrders
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.totalOrders && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    totalOrders: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>{`Progress (Total Orders)`}</div>{' '}
            <div
              className={`${
                sortedField.progressTotalOrders
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.progressTotalOrders && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    progressTotalOrders: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Delivered</div>{' '}
            <div
              className={`${
                sortedField.delivered
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.delivered && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    delivered: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>{`Progress (Delivered)`}</div>{' '}
            <div
              className={`${
                sortedField.progressDeliveredOrders
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.progressDeliveredOrders && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    progressDeliveredOrders: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Profit Per Product</div>{' '}
            <div
              className={`${
                sortedField.profitPerProduct
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.profitPerProduct && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    profitPerProduct: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>{`Progress (Profit Per Product)`}</div>{' '}
            <div
              className={`${
                sortedField.progressProfitPPP
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.progressProfitPPP && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    progressProfitPPP: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Total Profit</div>{' '}
            <div
              className={`${
                sortedField.totalProfit
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.totalProfit && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    totalProfit: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>{`Progress (Total Profit)`}</div>{' '}
            <div
              className={`${
                sortedField.progressProfit
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.progressProfit && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    progressProfit: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Buying Price</div>{' '}
            <div
              className={`${
                sortedField.buyingRate
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.buyingRate && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    buyingRate: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Delivered Percentage</div>{' '}
            <div
              className={`${
                sortedField.deliveredPercentage
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.deliveredPercentage && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    deliveredPercentage: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>ROI</div>{' '}
            <div
              className={`${
                sortedField.roi
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.roi && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    roi: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Returned</div>{' '}
            <div
              className={`${
                sortedField.returned
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.returned && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    returned: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Loss</div>{' '}
            <div
              className={`${
                sortedField.loss
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.loss && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    loss: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Pending</div>{' '}
            <div
              className={`${
                sortedField.pendingOrders
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.pendingOrders && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    pendingOrders: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Ready To Ship</div>{' '}
            <div
              className={`${
                sortedField.readyToShipOrders
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.readyToShipOrders && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    readyToShipOrders: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Shipped</div>{' '}
            <div
              className={`${
                sortedField.shippedOrders
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.shippedOrders && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    shippedOrders: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Canceled</div>{' '}
            <div
              className={`${
                sortedField.canceled
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.canceled && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    canceled: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>
          <div className='tableAnalysis__headerFlex'>
            <div>Failed</div>{' '}
            <div
              className={`${
                sortedField.failed
                  ? sortedIconClassName
                  : 'tableAnalysis__headerSortedIcon'
              }`}
              onClick={() => {
                if (sortedField.failed && !ascendingOrder) {
                  setAscendingOrder(true);
                } else {
                  setSortedField({
                    ...noSortedField,
                    failed: true,
                  });
                  setAscendingOrder(false);
                }
              }}>
              <SortIcon />
            </div>
          </div>
        </Th>
        <Th className={`${cssClass.headerContainer}`}>Group Actions</Th>
        
      </Tr>
    </Thead>
  );
};
