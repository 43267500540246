import { FC, Fragment, useEffect, useRef, useState, useContext } from 'react';
import { actions } from '../../../dispatch';
import { dispatch } from '../../../dispatch/dispatch';
import ContextStore from '../../../hooks/ContextStore';
import { IDeleteResponse } from '../../../interfaces/responses/DeleteResponse.interface';
import { IErrorResponse } from '../../../interfaces/responses/ErrorResponse.interface';

import './Logs.component.css';

export const Logs: FC = () => {
  const { contextStore, setContextStore } = useContext(ContextStore);
  const bottomRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current!.scrollTop = bottomRef.current!.scrollHeight;
  }, [contextStore.logs]);
  const onClickClearLog = async () => {
    contextStore.setShowSpinner(true)
    const clearLogResponse: IDeleteResponse | IErrorResponse = await dispatch(actions.clearLog, {}, contextStore.token, {storeId: contextStore.store._id})
    if("error" in clearLogResponse){
      contextStore.setShowSpinner(false)
      return console.log(clearLogResponse.error.message)
    }
    contextStore.setShowSpinner(false)
    setContextStore((past: any) => {
      return {...past, logs: []}
    })
  }
  return (
    <Fragment>
      <div className='tableAnalysis__nameContainer mtb-1'>
        <div className='tableAnalysis__name'>Log </div>

        {contextStore.store._id && <div className='analytics__createGroupButton button bg-danger' onClick={onClickClearLog}>
          Clear Log
        </div>}
      </div>
      <div className='logs' ref={bottomRef}>
        {contextStore.logs.map((log, index) => (
          <div className='log'>
            {'> '} {`${log.message} ${new Date(log.date).toLocaleString()}`}
          </div>
        ))}
        {/* <div ref={bottomRef} /> */}
      </div>
    </Fragment>
  );
};
