import React, { FC, Fragment, useState, useEffect, useContext } from 'react';
import './Table.component.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Analytics } from '../Analytics/Analytics.component';
import EditIcon from '@mui/icons-material/Edit';
import ExpandIcon from '@mui/icons-material/Expand';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {
  AddToGroupModal,
  CreateGroupModal,
  EditSkuModal,
} from '../../../modals';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Pagination } from '../../common';
import { IProductResponse } from '../../../interfaces/responses/ProductResponse.interface';
import { ISkuTableData } from '../../../interfaces/formData/SkuTableData.interface';
import ContextStore from '../../../hooks/ContextStore';
import { IProductGroupResponse } from '../../../interfaces/responses/ProductGroupResponse.interface';
import { useParams } from 'react-router-dom';
import { IOverviewTableValues } from '../../../interfaces/formData/OverViewTableValues.interface';
import { SkuTableHead } from './SkuTableHead.component';
import { ISortedField } from '../../../interfaces/table/sortedField';
import { IDeleteResponse } from '../../../interfaces/responses/DeleteResponse.interface';
import { IErrorResponse } from '../../../interfaces/responses/ErrorResponse.interface';
import { dispatch } from '../../../dispatch/dispatch';
import { actions } from '../../../dispatch';
import { AddAllToGroupModal } from '../../../modals/group/AddAllToGroup.modal';
import { IAnalyticsResponse } from '../../../interfaces/responses/AnalyticsResponse.interface';
import { Popover } from '@mui/material';

export const SkuTable: FC = () => {
  const { id, type } = useParams();
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'image-popover' : undefined;
  const [popOverImgUri, setPopOverImgUri] = useState('');
  const onMouseOverSku = (e: React.SyntheticEvent<any>, imgUri: string) => {
    console.log('Mouse Over');
    setPopOverImgUri(imgUri);
    setAnchorEl(e.currentTarget);
  };
  const onMouseOutSku = () => {
    console.log('Mouse Out');
    setAnchorEl(null);
  };
  const { contextStore, setContextStore } = useContext(ContextStore);
  const [productsToGroup, setProductsToGroup] = useState<
    Array<IProductResponse>
  >([]);
  const [selectedProduct, setSelectedProduct] = useState<IProductResponse>(
    {} as IProductResponse
  );
  const [showCreateGroupModal, setShowCreateGroupModal] =
    useState<boolean>(false);
  const [showAddAllToGroupModal, setShowAddAllToGroupModal] =
    useState<boolean>(false);
  const [showAddToGroupModal, setShowAddToGroupModal] =
    useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  let totalPages = Math.floor(contextStore.products.length / 40) + 1;
  console.log(totalPages);
  console.log(pageNo);
  const [filterSkuTableData, setFilterSkuTableData] = useState<
    Array<ISkuTableData>
  >([]);
  const [graphData, setGraphData] = useState<Array<IAnalyticsResponse>>([]);
  const [expandRowNo, setExpandRowNo] = useState<number | undefined>();
  const [skuTableData, setSkuTableData] = useState<Array<ISkuTableData>>([]);
  const [tableValues, setTableValues] = useState<IOverviewTableValues>({
    deliveredOrders: 0,
    canceledOrders: 0,
    returnedOrders: 0,
    deliveredPercentage: 0,
    totalPayout: 0,
    totalBuyingRate: 0,
    totalTransportFees: 0,
    totalStorageFees: 0,
    totalPackageFees: 0,
    totalProfit: 0,
    roi: 0,
    profitPerProduct: 0,
    failedOrders: 0,
    totalOrders: 0,
    totalLoss: 0,
    progressDeliveredOrders: 0,
    progressProfit: 0,
    shippedOrders: 0,
    pendingOrders: 0,
    readyToShipOrders: 0,
    progressTotalOrders: 0
  });
  const [searchProductGroup, setSearchProductGroup] = useState<string>('');
  const [searchProductStore, setSearchProductStore] = useState<string>('');
  const [searchSku, setSearchSku] = useState<string>('');
  useEffect(() => {
    setSkuTableData(
      filterSkuTableData.filter(
        (skuTableData) =>
          skuTableData.product.Sku.toLowerCase().includes(
            searchSku.toLowerCase()
          ) ||
          (skuTableData.product.name &&
            skuTableData.product.name
              .toLowerCase()
              .includes(searchSku.toLowerCase()))
      )
    );
  }, [searchSku]);
  useEffect(() => {
    setSkuTableData(
      filterSkuTableData.filter(skuTableData => skuTableData.product.store && skuTableData.product.store.name.toLowerCase().includes(searchProductStore.toLowerCase()))
    )
  }, [searchProductStore])
  useEffect(() => {
    setSkuTableData(
      filterSkuTableData.filter(
        (skuTableData) =>
          skuTableData.product.productGroup &&
          skuTableData.product.productGroup.name &&
          skuTableData.product.productGroup.name
            .toLowerCase()
            .includes(searchProductGroup.toLowerCase())
      )
    );
  }, [searchProductGroup]);
  const [ascendingOrder, setAscendingOrder] = useState<boolean>(false);
  const noSortedField: ISortedField = {
    delivered: false,
    canceled: false,
    returned: false,
    failed: false,
    totalOrders: false,
    totalProfit: false,
    profitPerProduct: false,
    deliveredPercentage: false,
    roi: false,
    loss: false,
    groupName: false,
    buyingRate: false,
    stockAvailable: false,
    progressDeliveredOrders: false,
    progressProfit: false,
    progressProfitPPP: false,
    shippedOrders: false,
    readyToShipOrders: false,
    pendingOrders: false,
    progressTotalOrders: false
  }
  const [sortedField, setSortedField] = useState<ISortedField>(noSortedField);
  useEffect(() => {
    const vTableData = [...skuTableData];
    if (sortedField.delivered) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.deliveredOrders - b.product.deliveredOrders;
          }
          return b.product.deliveredOrders - a.product.deliveredOrders;
        })
      );
    }
    if (sortedField.canceled) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.canceledOrders - b.product.canceledOrders;
          }
          return b.product.canceledOrders - a.product.canceledOrders;
        })
      );
    }
    if (sortedField.returned) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.returnedOrders - b.product.returnedOrders;
          }
          return b.product.returnedOrders - a.product.returnedOrders;
        })
      );
    }
    if (sortedField.failed) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.failedOrders - b.product.failedOrders;
          }
          return b.product.failedOrders - a.product.failedOrders;
        })
      );
    }
    if (sortedField.totalOrders) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return (
              (a.product.deliveredOrders +
              a.product.returnedOrders +
              a.product.canceledOrders +
              a.product.failedOrders + a.product.shippedOrders + a.product.readyToShipOrders + a.product.pendingOrders) -
              (b.product.deliveredOrders +
                b.product.returnedOrders +
                b.product.canceledOrders +
                b.product.failedOrders + b.product.shippedOrders + b.product.readyToShipOrders + b.product.pendingOrders)
            );
          }
          return (
            b.product.deliveredOrders +
            b.product.returnedOrders +
            b.product.canceledOrders +
            b.product.failedOrders + b.product.shippedOrders + b.product.readyToShipOrders + b.product.pendingOrders  -
            (a.product.deliveredOrders +
              a.product.returnedOrders +
              a.product.canceledOrders +
              a.product.failedOrders + a.product.shippedOrders + a.product.readyToShipOrders + a.product.pendingOrders)
          );
        })
      );
    }
    if (sortedField.totalProfit) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.totalProfit - b.product.totalProfit;
          }
          return b.product.totalProfit - a.product.totalProfit;
        })
      );
    }
    if (sortedField.profitPerProduct) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.profitPerProduct - b.product.profitPerProduct;
          }
          return b.product.profitPerProduct - a.product.profitPerProduct;
        })
      );
    }
    if (sortedField.deliveredPercentage) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return (
              a.product.deliveredPercentage - b.product.deliveredPercentage
            );
          }
          return b.product.deliveredPercentage - a.product.deliveredPercentage;
        })
      );
    }
    if (sortedField.roi) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.roi - b.product.roi;
          }
          return b.product.roi - a.product.roi;
        })
      );
    }
    if (sortedField.loss) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.loss - b.product.loss;
          }
          return b.product.loss - a.product.loss;
        })
      );
    }
    if (sortedField.buyingRate) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.buyingRate - b.product.buyingRate;
          }
          return b.product.buyingRate - a.product.buyingRate;
        })
      );
    }
    if (sortedField.progressDeliveredOrders) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.progressDeliveredOrders - b.product.progressDeliveredOrders;
          }
          return b.product.progressDeliveredOrders - a.product.progressDeliveredOrders;
        })
      );
    }
    if (sortedField.progressProfit) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.progressProfit - b.product.progressProfit;
          }
          return b.product.progressProfit - a.product.progressProfit;
        })
      );
    }
    if (sortedField.progressProfitPPP) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.progressProfitPPP - b.product.progressProfitPPP;
          }
          return b.product.progressProfitPPP - a.product.progressProfitPPP;
        })
      );
    }
    if (sortedField.pendingOrders) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.pendingOrders - b.product.pendingOrders;
          }
          return b.product.pendingOrders - a.product.pendingOrders;
        })
      );
    }
    if (sortedField.shippedOrders) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.shippedOrders - b.product.shippedOrders;
          }
          return b.product.shippedOrders - a.product.shippedOrders;
        })
      );
    }
    if (sortedField.readyToShipOrders) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.readyToShipOrders - b.product.readyToShipOrders;
          }
          return b.product.readyToShipOrders - a.product.readyToShipOrders;
        })
      );
    }
    if (sortedField.progressTotalOrders) {
      setSkuTableData(
        vTableData.sort((a, b) => {
          if (ascendingOrder) {
            return a.product.progressTotalOrders - b.product.progressTotalOrders;
          }
          return b.product.progressTotalOrders - a.product.progressTotalOrders;
        })
      );
    }

  }, [ascendingOrder, sortedField]);
  const vTableData = [
    { name: 'Number of orders delivered', value: tableValues.deliveredOrders },
    { name: 'Number of orders canceled', value: tableValues.canceledOrders },
    { name: 'Number of orders returned', value: tableValues.returnedOrders },
    { name: 'Number of order failed', value: tableValues.failedOrders },
    {
      name: 'Percentage or orders Delivered',
      value: tableValues.deliveredPercentage ? tableValues.deliveredPercentage.toFixed(0) : 0,
    },
    { name: 'Total Orders', value: tableValues.totalOrders },
    { name: 'Total Payout', value: tableValues.totalPayout.toFixed(2) },
    {
      name: 'Total Product Buying Cost',
      value: tableValues.totalBuyingRate.toFixed(2),
    },
    {
      name: 'Total Product Transport Fees',
      value: tableValues.totalTransportFees.toFixed(2),
    },
    {
      name: 'Total Product Storage Fees',
      value: tableValues.totalStorageFees.toFixed(2),
    },
    {
      name: 'Total Product Package Fees',
      value: tableValues.totalPackageFees.toFixed(2),
    },
    { name: 'Total Profit', value: tableValues.totalProfit.toFixed(2) },
    { name: 'roi(%)', value: tableValues.roi.toFixed(0) },
    { name: 'Profit Per Product', value: tableValues.profitPerProduct },
    { name: 'Loss', value: tableValues.totalLoss },
  ];
  const onExpandRow = async (
    productTableData: ISkuTableData,
    index: number
  ) => {
    const { product } = productTableData;
    expandRowNo === index + 1
      ? setExpandRowNo(undefined)
      : setExpandRowNo(index + 1);
    setTableValues({
      deliveredOrders: product.deliveredOrders,
      canceledOrders: product.canceledOrders,
      returnedOrders: product.returnedOrders,
      deliveredPercentage: product.deliveredPercentage,
      totalPayout: product.totalPayout,
      totalBuyingRate: product.totalBuyingRate,
      totalTransportFees: product.totalTransportFees,
      totalStorageFees: product.totalStorageFees,
      totalPackageFees: product.totalPackageFees,
      totalProfit: product.totalProfit,
      roi: product.roi,
      profitPerProduct: product.profitPerProduct,
      failedOrders: product.failedOrders,
      totalOrders:
        product.deliveredOrders +
        product.returnedOrders +
        product.canceledOrders +
        product.failedOrders,
      totalLoss: product.loss,
      progressDeliveredOrders: 0,
    progressProfit: 0,
    shippedOrders: product.shippedOrders,
    pendingOrders: product.pendingOrders,
    readyToShipOrders: product.readyToShipOrders,
    progressTotalOrders: product.progressTotalOrders
    });
    if (!(expandRowNo === index + 1)) {
      contextStore.setShowSpinner(true);
      const analyticsResponse: Array<IAnalyticsResponse> | IErrorResponse =
        await dispatch(
          actions.getAnalyticsProduct,
          {
            fromDate: contextStore.date.fromDate.toISOString(),
            toDate: contextStore.date.toDate.toISOString(),
            productId: productTableData.product._id,
            type
          },
          contextStore.token
        );
      if ('error' in analyticsResponse) {
        contextStore.setShowSpinner(false);
        return console.log(analyticsResponse.error.message);
      }
      setGraphData(analyticsResponse);
      contextStore.setShowSpinner(false);
    }
  };
  // const onClickEdit = (product: IProductResponse) => {
  //   setSelectedProduct(product);
  //   setShowModal(true);
  // };

  const updateProducts = (products: Array<IProductResponse>) => {
    console.log('Products Table Being');
    console.log(products);
    let vData: Array<ISkuTableData> = [];
    products.map((product) => {
      vData.push({ product, checked: false });
    });
    setSkuTableData(vData);
    setFilterSkuTableData(vData);
    setExpandRowNo(undefined);
  };
  const handleCheck = (productId: string) => {
    const filterData = filterSkuTableData.map((item) =>
      item.product._id === productId
        ? { ...item, checked: !item.checked }
        : item
    );

    const data = skuTableData.map((item) =>
      item.product._id === productId
        ? { ...item, checked: !item.checked }
        : item
    );
    setSkuTableData(data);
    setFilterSkuTableData(filterData);
  };
  useEffect(() => {
    let vproductsToGroup = [] as Array<IProductResponse>;
    filterSkuTableData.map((tableEntry) => {
      if (tableEntry.checked) {
        vproductsToGroup.push(tableEntry.product);
      }
    });
    setProductsToGroup(vproductsToGroup);
  }, [filterSkuTableData]);
  useEffect(() => {
    console.log('Use Effect On sku table');
    setContextStore((past: any) => {
      return {
        ...past,
        productTrigger: updateProducts,
      };
    });
  }, []);
  const onClickReset = (e: any) => {
    updateProducts(contextStore.products);
    setSearchSku('');
    setSearchProductGroup('');
    setSortedField(noSortedField);
  };
  const onClickRemoveProduct = async (productId: string) => {
    contextStore.setShowSpinner(true);
    const removeFromGroupResponse: IDeleteResponse | IErrorResponse =
      await dispatch(actions.removeProduct, { productId }, contextStore.token, {
        productId,
      });
    if ('error' in removeFromGroupResponse) {
      contextStore.setShowSpinner(false);
      return console.log(removeFromGroupResponse.error.message);
    }
    const productResponse: IProductResponse | IErrorResponse = await dispatch(
      actions.getProduct,
      {
        fromDate: contextStore.date.fromDate,
        toDate: contextStore.date.toDate,
      },
      contextStore.token,
      { id: productId }
    );
    if ('error' in productResponse) {
      contextStore.setShowSpinner(false);
      return console.log(productResponse.error.message);
    }
    console.log(productResponse);
    const index = contextStore.products.findIndex(
      (vProduct) => vProduct._id === productId
    );
    const vProducts = contextStore.products;
    vProducts[index] = productResponse;
    contextStore.productGroupTrigger(contextStore.productGroups, vProducts);
    contextStore.overviewTrigger(vProducts);
    contextStore.productTrigger(vProducts);
    contextStore.setShowSpinner(false);
    setContextStore((past: any) => {
      return {
        ...past,
        products: vProducts,
      };
    });
  };
  const [legend, setLegend] = useState<string[]>(['totalProfit']);
  const selectLegend = (value: string) => {
    setLegend([...legend, value]);
  };

  const deselectLegend = (value: string) => {
    const filteredOptions = legend.filter((l) => l !== value);
    setLegend([...filteredOptions]);
  };

  const handleLegend = (value: string) => {
    legend.some((l) => l === value)
      ? deselectLegend(value)
      : selectLegend(value);
  };
  const [cssClass, setCssClass] = useState({
    tableContainer: "tableAnalysis__container"
  })
  useEffect(() => {
    if(type === "order"){
      setCssClass({...cssClass, tableContainer: "tableAnalysis__container__green"})
    }
  }, [type])

  return (
    <Fragment>
      <div className='tableAnalysis__nameContainer'>
        <div className='tableAnalysis__name'>
          Manage SKU {`(${productsToGroup.length} Selected Products)`}{' '}
          <button onClick={onClickReset}>Reset</button>
        </div>
        {productsToGroup.length > 0 ? (
          <div
            className='analytics__createGroupButton button bg-blue'
            onClick={() => {
              setShowAddAllToGroupModal(true);
            }}>
            Add To Group
          </div>
        ) : (
          <div className='analytics__createGroupButton button bg-secondaryWhite'>
            Add To Group
          </div>
        )}

        <div
          className='analytics__createGroupButton button bg-blue text-white'
          onClick={() => {
            setShowCreateGroupModal(true);
          }}>
          Create Group
        </div>
      </div>
      <div className={`${cssClass.tableContainer}`}>
        <div className='tableAnalysis__scroll'>
          <Table className='tableAnalysis'>
            <SkuTableHead
              sortedField={sortedField}
              setSortedField={setSortedField}
              ascendingOrder={ascendingOrder}
              setAscendingOrder={setAscendingOrder}
              searchSku={searchSku}
              setSearchSku={setSearchSku}
              searchProductGroup={searchProductGroup}
              setSearchProductGroup={setSearchProductGroup}
              searchProductStore={searchProductStore}
              setSearchProductStore={setSearchProductStore}
            />
            <Tbody className='tableAnalysis__row'>
              {skuTableData.map(
                (data, index) =>
                  index < pageNo * 40 &&
                  index >= (pageNo - 1) * 40 && (
                    <Fragment key={index}>
                      <Tr>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {!data.product.productGroup && (
                            <span
                              className='table__span'
                              onClick={() => handleCheck(data.product._id)}>
                              {data.checked ? (
                                <RadioButtonCheckedIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </span>
                          )}
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          <a href={`${data.product.url}`}>{data.product.Sku}</a>
                          <Popover
                            id={popoverId}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            onClick={onMouseOutSku}>
                            <>
                              <img
                                src={popOverImgUri}
                                style={{ height: '200px', width: '200px' }}
                              />
                            </>
                          </Popover>
                        </Td>
                        <Td
                          className={`tableAnalysis__data  ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}
                          onClick={(e: React.MouseEvent<HTMLTableElement>) => {
                            onMouseOverSku(e, data.product.imgUri);
                          }}>
                          {data.product.name}
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.productGroup &&
                            data.product.productGroup.name}
                        </Td>
                        {!id && <Td>
                          {data.product.store && data.product.store.name}
                        </Td>}
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          <div className='actionButton__span'>
                            <span
                              className='table__span'
                              onClick={() => {
                                onExpandRow(data, index);
                              }}>
                              <ExpandIcon />
                            </span>
                            {/* <span
                          className='table__span'
                          onClick={() => {
                            onClickEdit(data.product);
                          }}>
                          <EditIcon />
                        </span> */}
                          </div>
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.deliveredOrders +
                            data.product.canceledOrders +
                            data.product.returnedOrders +
                            data.product.failedOrders + data.product.pendingOrders + data.product.shippedOrders + data.product.readyToShipOrders}
                        </Td>
                        {
                          data.product.progressTotalOrders < 0 ? 
                          <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          <p style={{color: "red"}}>{data.product.progressTotalOrders.toFixed(0)} %</p>
                        </Td> :
                        <Td
                        className={`tableAnalysis__data ${
                          data.checked ? 'bg-secondaryWhite' : ''
                        }`}>
                        <p style={{color: "green"}}>{data.product.progressTotalOrders.toFixed(0)} %</p>
                      </Td>
                        }
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.deliveredOrders}
                        </Td>
                        {
                          data.product.progressDeliveredOrders < 0 ? 
                          <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          <p style={{color: "red"}}>{data.product.progressDeliveredOrders.toFixed(0)} %</p>
                        </Td> :
                        <Td
                        className={`tableAnalysis__data ${
                          data.checked ? 'bg-secondaryWhite' : ''
                        }`}>
                        <p style={{color: "green"}}>{data.product.progressDeliveredOrders.toFixed(0)} %</p>
                      </Td>
                        }
                                                <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.profitPerProduct.toFixed(2)} MAD
                        </Td>
                        {
                          data.product.progressProfitPPP < 0 ? 
                          <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          <p style={{color: "red"}}>{data.product.progressProfitPPP.toFixed(0)} %</p>
                        </Td> :
                        <Td
                        className={`tableAnalysis__data ${
                          data.checked ? 'bg-secondaryWhite' : ''
                        }`}>
                        <p style={{color: "green"}}>{data.product.progressProfitPPP.toFixed(0)} %</p>
                      </Td>
                        }
                                                <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.totalProfit.toFixed(2)} MAD
                        </Td>
                        {
                          data.product.progressProfit < 0 ? 
                          <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          <p style={{color: "red"}}>{data.product.progressProfit.toFixed(0)} %</p>
                        </Td> :
                        <Td
                        className={`tableAnalysis__data ${
                          data.checked ? 'bg-secondaryWhite' : ''
                        }`}>
                        <p style={{color: "green"}}>{data.product.progressProfit.toFixed(0)} %</p>
                      </Td>
                        }
                                                <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.productGroup
                            ? data.product.productGroup.buyingRate
                            : 0}
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.deliveredPercentage &&
                            data.product.deliveredPercentage.toFixed(0)}{' '}
                          %
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.roi.toFixed(0)} %
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.returnedOrders}
                        </Td>
                        
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.loss} MAD
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.pendingOrders}
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.readyToShipOrders}
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.shippedOrders}
                        </Td>
                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.canceledOrders}
                        </Td>

                        <Td
                          className={`tableAnalysis__data ${
                            data.checked ? 'bg-secondaryWhite' : ''
                          }`}>
                          {data.product.failedOrders}
                        </Td>
                        <Td>
                          {data.product.productGroup ? (
                            <button
                              onClick={() => {
                                onClickRemoveProduct(data.product._id);
                              }}>
                              Remove From Group
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setSelectedProduct(data.product);
                                setShowAddToGroupModal(true);
                              }}>
                              Add To Group
                            </button>
                          )}
                        </Td>
                      </Tr>
                      {expandRowNo && expandRowNo === index + 1 && (
                        <Tr>
                          <Td
                            className={`tableAnalysis__data ${
                              data.checked ? 'bg-secondaryWhite' : ''
                            }`}
                            colspan='7'>
                            <div className='tableAnalysis__expand'>
                              {vTableData.map((data, index) => (
                                <>
                                  <div>{data.name}</div>
                                  <div className='text-end'>{data.value}</div>
                                </>
                              ))}
                            </div>
                            <div className='tableAnalysis__expand_analytics'>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '1rem',
                                  margin: '1rem 0rem',
                                }}>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some((l) => l === 'totalProfit') &&
                                    'bg-blue'
                                  }`}
                                  onClick={() => {
                                    handleLegend('totalProfit');
                                  }}>
                                  Total Profit
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some(
                                      (l) => l === 'deliveredOrders'
                                    ) && 'bg-blue'
                                  }`}
                                  onClick={() => {
                                    handleLegend('deliveredOrders');
                                  }}>
                                  Delivered Orders
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some(
                                      (l) => l === 'returnedOrders'
                                    ) && 'bg-blue'
                                  }`}
                                  onClick={() => {
                                    handleLegend('returnedOrders');
                                  }}>
                                  Returned Orders
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some((l) => l === 'failedOrders') &&
                                    'bg-blue'
                                  }`}
                                  onClick={() => {
                                    handleLegend('failedOrders');
                                  }}>
                                  Failed Orders
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some(
                                      (l) => l === 'canceledOrders'
                                    ) && 'bg-blue'
                                  }`}
                                  onClick={() => {
                                    handleLegend('canceledOrders');
                                  }}>
                                  Canceled Orders
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some((l) => l === 'totalOrders') &&
                                    'bg-blue'
                                  }`}
                                  onClick={() => {
                                    handleLegend('totalOrders');
                                  }}>
                                  Total Orders
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some((l) => l === 'shippedOrders') && 'bg-blue text-white'
                                  }`}
                                  onClick={() => {
                                    handleLegend('shippedOrders');
                                  }}>
                                  Shipped Orders
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some((l) => l === 'readyToShipOrders') && 'bg-blue text-white'
                                  }`}
                                  onClick={() => {
                                    handleLegend('readyToShipOrders');
                                  }}>
                                  RTS Orders
                                </div>
                                <div
                                  className={`analytics__legendOutline ${
                                    legend.some((l) => l === 'pendingOrders') && 'bg-blue text-white'
                                  }`}
                                  onClick={() => {
                                    handleLegend('pendingOrders');
                                  }}>
                                  Pending Orders
                                </div>
                              </div>
                              <ResponsiveContainer width='99%' aspect={7}>
                                <AreaChart
                                  data={graphData}
                                  margin={{
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                  }}>
                                  {/* <defs>
                <linearGradient
                  id='colortotalProfit'
                  x1='0'
                  y1='0'
                  x2='0'
                  y2='1'>
                  <stop offset='5%' stopColor='#00aeef' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#00aeef' stopOpacity={0} />
                </linearGradient>
              </defs> */}
                                  <XAxis dataKey='date' />
                                  <YAxis />
                                  <CartesianGrid strokeDasharray='3 3' />
                                  <Tooltip />
                                  {/* <Legend verticalAlign='top' height={40} /> */}

                                  {legend.some((l) => l === 'totalProfit') && (
                                    <Area
                                      type='monotone'
                                      dataKey='totalProfit'
                                      // name='New Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#00aeef'
                                    />
                                  )}
                                  {legend.some(
                                    (l) => l === 'deliveredOrders'
                                  ) && (
                                    <Area
                                      type='monotone'
                                      dataKey='deliveredOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#52b041'
                                    />
                                  )}
                                  {legend.some(
                                    (l) => l === 'returnedOrders'
                                  ) && (
                                    <Area
                                      type='monotone'
                                      dataKey='returnedOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#b04141'
                                    />
                                  )}
                                  {legend.some((l) => l === 'failedOrders') && (
                                    <Area
                                      type='monotone'
                                      dataKey='failedOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#b0aa41'
                                    />
                                  )}
                                  {legend.some(
                                    (l) => l === 'canceledOrders'
                                  ) && (
                                    <Area
                                      type='monotone'
                                      dataKey='canceledOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#b07741'
                                    />
                                  )}
                                  {legend.some((l) => l === 'totalOrders') && (
                                    <Area
                                      type='monotone'
                                      dataKey='totalOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#b041b0'
                                    />
                                  )}
                                  {legend.some((l) => l === 'pendingOrders') && (
                                    <Area
                                      type='monotone'
                                      dataKey='pendingOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#223a78'
                                    />
                                  )}
                                  {legend.some((l) => l === 'shippedOrders') && (
                                    <Area
                                      type='monotone'
                                      dataKey='shippedOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#6f2278'
                                    />
                                  )}
                                  {legend.some((l) => l === 'readyToShipOrders') && (
                                    <Area
                                      type='monotone'
                                      dataKey='readyToShipOrders'
                                      // name='Old Patient'
                                      stroke='#121212'
                                      fillOpacity={0.5}
                                      fill='#78223f'
                                    />
                                  )}
                                </AreaChart>
                              </ResponsiveContainer>
                            </div>
                          </Td>
                        </Tr>
                      )}
                    </Fragment>
                  )
              )}
            </Tbody>
          </Table>
        </div>
        <Pagination
          pageNo={pageNo}
          totalPages={totalPages}
          setPageNo={setPageNo}
        />
      </div>
      {/* {showModal && (
        <EditSkuModal setShowModal={setShowModal} product={selectedProduct} />
      )} */}
      {showCreateGroupModal && (
        <CreateGroupModal
          setShowModal={setShowCreateGroupModal}
          products={productsToGroup}
        />
      )}
      {showAddAllToGroupModal && (
        <AddAllToGroupModal
          setShowModal={setShowAddAllToGroupModal}
          products={productsToGroup}
        />
      )}
      {showAddToGroupModal && (
        <AddToGroupModal
          setShowAddToGroupModal={setShowAddToGroupModal}
          product={selectedProduct}
        />
      )}
    </Fragment>
  );
};
