import { FC, Fragment } from 'react';
import { Header } from '../components/common';
import { ProductTable, SkuTable } from '../components/dashboard';
import {
  AnalyticsAll,

} from '../components/productGroup';

const ProductGroup: FC = () => {
  return (
    <Fragment>
        <AnalyticsAll />
        <SkuTable />
      <ProductTable />

    </Fragment>
  );
};

export default ProductGroup;
